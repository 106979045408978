define("blackcat/mixins/error-messages-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    errorMessages: Ember.computed('attribute', 'model.errors.[]', function () {
      const errorsForAttr = this.get('model.errors');

      if (Ember.isPresent(errorsForAttr)) {
        return errorsForAttr.filter(function (error) {
          return Ember.isPresent(error.attribute, this.attribute);
        }, this).map(error => error.message);
      }

      return Ember.A([]);
    })
  });

  _exports.default = _default;
});