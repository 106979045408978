define("blackcat/components/additional-settings/component", ["exports", "blackcat/mixins/business-form-mixin"], function (_exports, _businessFormMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_businessFormMixin.default, {});

  _exports.default = _default;
});