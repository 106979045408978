define("blackcat/components/login-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    classNames: ['loginForm'],

    init() {
      this._super(...arguments);

      this.setProperties({
        email: null,
        password: null,
        errorMessage: null
      });
    },

    actions: {
      logIn() {
        return new Ember.RSVP.Promise((resolve, reject) => {
          this.session.authenticate('authenticator:token', {
            identification: this.email,
            password: this.password
          }).then(() => {
            this.set('errorMessage', null);
            resolve();
          }).catch(() => {
            this.set('errorMessage', 'Invalid credentials.');
            reject();
          }).finally(() => {
            this.set('password', null);
          });
        });
      }

    }
  });

  _exports.default = _default;
});