define("blackcat/industries/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    title: 'New Industry',
    ajax: Ember.inject.service(),

    model() {
      return this.ajax.request('/schemas/Other', {
        method: 'GET'
      }).then(response => this.store.createRecord('industry', {
        businessSchemaTemplate: response
      }));
    }

  });

  _exports.default = _default;
});