define("blackcat/businesses/business/edit/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "blackcat/utils/salesforceDataSerializer"], function (_exports, _authenticatedRouteMixin, _salesforceDataSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ability: Ember.inject.service('can'),

    beforeModel() {
      if (!this.ability.can('update business')) {
        this.transitionTo('businesses');
      }
    },

    model() {
      return this.modelFor('businesses.business');
    },

    setupController(controller, model) {
      this._super(...arguments);

      if (Ember.isPresent(model.trialEndDate)) {
        controller.set('selectedTrialEndDate', model.trialEndDate.toDate());
      }

      const salesforceData = (0, _salesforceDataSerializer.default)(model.salesforceData);
      controller.set('salesforceData', salesforceData);
    },

    renderTemplate(_, model) {
      this.render('businesses/business/edit', {
        into: 'businesses',
        outlet: 'businesses',
        model
      });
    },

    actions: {
      transitionToBusiness(businessId) {
        this.transitionTo('businesses.business', businessId);
      }

    }
  });

  _exports.default = _default;
});