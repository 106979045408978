define("blackcat/constants/business", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    consumptionModel: {
      WALLET_CREDIT: 'Wallet Credit'
    },
    channels: {
      SMS: 'sms',
      FACEBOOK: 'facebook',
      WECHAT: 'wechat',
      WHATSAPP: 'whatsapp',
      LIVE_CHAT: 'webchat',
      EMAIL: 'email'
    },
    channelsInputs: ['sms', 'facebook', 'wechat', 'whatsapp', 'webchat', 'email'],
    defaultOptions: {
      DEFAULT_IMPORT_PHONE_FORMAT: 'international',
      DEFAULT_IMPORT_DATE_ENDIANNESS: 'little'
    },
    subscriptionNames: {
      LIVE_EXPERIENCE: 'live experience',
      LIVE_SURVEY: 'live survey'
    }
  };
  _exports.default = _default;
});