define("blackcat/businesses/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    title: 'Businesses',

    model() {
      return this.store.query('business', {
        limit: 10,
        inactive: true,
        include: 'business_settings,balance,business_channels,subscription'
      });
    },

    renderTemplate(_, model) {
      this.render('businesses/index', {
        into: 'businesses',
        outlet: 'businesses',
        model
      });
    },

    actions: {
      transitionToBusiness(businessId) {
        this.transitionTo('businesses.business', businessId);
      }

    }
  });

  _exports.default = _default;
});