define("blackcat/abilities/business", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    session: Ember.inject.service(),
    canDelete: Ember.computed.readOnly('session.currentUser.isAdmin'),
    canUpdate: Ember.computed.readOnly('session.currentUser.isAdmin'),
    canManageUsers: Ember.computed.readOnly('session.currentUser.isAdmin')
  });

  _exports.default = _default;
});