define("blackcat/router", ["exports", "blackcat/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Routes = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Routes.map(function () {
    // eslint-disable-line array-callback-return
    this.route('industries', function () {
      this.route('new');
      this.route('show', {
        path: '/:id'
      });
    });
    this.route('users');
    this.route('login');
    this.route('ping');
    this.route('settings');
    this.route('businesses', function () {
      this.route('new');
      this.route('business', {
        path: '/:businessId'
      }, function () {
        this.route('edit');
        this.route('integrations', function () {
          this.route('show', {
            path: '/:integrationId'
          });
        });
      });
    });
  });
  Routes.reopen({
    pageHeader: Ember.inject.service('page-header'),

    setTitle(title) {
      this._super(title);

      this.set('pageHeader.title', title);
    }

  });
  var _default = Routes;
  _exports.default = _default;
});