define("blackcat/mirage/factories/balance", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.default.Factory.extend({
    id() {
      return _emberCliMirage.faker.random.uuid();
    },

    business: (0, _emberCliMirage.belongsTo)(),
    amount: _emberCliMirage.faker.random.number(100),
    currency: 'USD',
    active: true,
    low: false
  });

  _exports.default = _default;
});