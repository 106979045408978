define("blackcat/utils/string-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toTitleCase = toTitleCase;
  _exports.toSnakeCase = toSnakeCase;

  function toTitleCase(str = '') {
    return toSnakeCase(str.toString()).split('_').map(Ember.String.capitalize).join(' ');
  }

  function toSnakeCase(str = '') {
    return Ember.String.underscore(str.toString()).toLowerCase();
  }
});