define("blackcat/components/editable-user/component", ["exports", "blackcat/mixins/error-messages-mixin"], function (_exports, _errorMessagesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_errorMessagesMixin.default, {
    tagName: '',

    init() {
      this._super(...arguments);

      this.setProperties({
        errorMessage: null,
        isEditing: false
      });
    },

    // Computed Properties
    name: Ember.computed.alias('model.name'),
    email: Ember.computed.alias('model.email'),
    isAdmin: Ember.computed.alias('model.isAdmin'),
    isNew: Ember.computed.alias('model.isNew'),
    isErrorMessageArray: Ember.computed('errorMessage', function () {
      return Array.isArray(this.errorMessage);
    }),
    actions: {
      edit() {
        Ember.set(this, 'isEditing', true);
      },

      save() {
        const user = this.model;
        user.validate().then(({
          validations
        }) => {
          if (validations.isValid) {
            user.save().then(() => {
              Ember.set(this, 'isEditing', false);

              if (Ember.isPresent(this.attrs.userSaved)) {
                // eslint-disable-line ember/no-attrs-in-components
                this.sendAction('userSaved');
                Ember.set(this, 'errorMessage', null);
              }
            }).catch(() => {
              Ember.set(this, 'errorMessage', this.errorMessages);
              user.rollbackAttributes();
            });
          } else {
            Ember.set(this, 'errorMessage', validations.errors);
          }
        });
      },

      delete() {
        this.model.destroyRecord();
      }

    }
  });

  _exports.default = _default;
});