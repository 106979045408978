define("blackcat/models/cluster", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Cluster {
    constructor({
      name,
      clusterId,
      address
    }) {
      this.name = name;
      this.clusterId = clusterId;
      this.address = address;
    }

    toString() {
      return `${this.name} - ${this.address} (${this.clusterId})`;
    }

  }

  _exports.default = Cluster;
});