define("blackcat/businesses/business/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    title() {
      return `Businesses - ${this.businessName}`;
    },

    model(params) {
      const includes = 'integrations,' + 'business_settings,' + 'subscription,' + 'balance,' + 'clusterId,' + 'phoneNumber,' + 'countryCode,' + 'salesforceData,' + 'business_channels';
      return this.store.findRecord('business', params.businessId, {
        include: includes
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      Ember.set(this, 'businessName', model.name);

      if (Ember.isPresent(Ember.get(model, 'salesforceData.trial_end_date'))) {
        Ember.set(model, 'trial', true);
        controller.set('selectedTrialEndDate', Ember.get(model, 'salesforceData.trial_end_date'));
      }
    },

    renderTemplate(_, model) {
      this.render('businesses/business', {
        into: 'businesses',
        outlet: 'businesses',
        model
      });
    },

    actions: {
      saveBusiness(business) {
        business.save().catch(e => {
          alert('There was a problem:', e);
        });
      }

    }
  });

  _exports.default = _default;
});