define("blackcat/serializers/application", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    // Use underscores for attributes and relationships
    keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    },

    keyForRelationship(rawKey) {
      return Ember.String.underscore(rawKey);
    },

    payloadKeyFromModelName() {
      return Ember.String.underscore(this._super(...arguments));
    },

    /*
     * Remove attribute from payload if blank value.
     */
    serializeAttribute(snapshot, json, key) {
      // Run default
      this._super(...arguments); // Determine payload key


      let payloadKey = this._getMappedKey(key, snapshot.type);

      if (payloadKey === key) {
        payloadKey = this.keyForAttribute(key, 'serialize');
      } // Remove key if blank and that attribute is not dirty


      const value = json.attributes[payloadKey];

      if (Ember.isBlank(value) && !Ember.isPresent(snapshot.changedAttributes()[key])) {
        delete json.attributes[payloadKey];
      }
    }

  });

  _exports.default = _default;
});