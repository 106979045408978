define("blackcat/mixins/admin-autocomplete-mixin", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    api: Ember.inject.service('v1-api'),
    filterName: null,
    usersFromClusterPromise: Ember.computed('business.clusterId', function () {
      const clusterId = Ember.get(this, 'business.clusterId');

      if (Ember.isPresent(clusterId)) {
        return this.api.usersFromCluster(clusterId);
      }

      return [];
    }),
    searchAdmins: (0, _emberConcurrency.task)(function* (filterName) {
      yield (0, _emberConcurrency.timeout)(300);

      if (Ember.isPresent(filterName) && filterName.length >= 3) {
        const clusterUsers = this.usersFromClusterPromise;
        return clusterUsers.then(result => result.filter(user => user.name.toLowerCase().startsWith(filterName.toLowerCase())));
      }

      return [];
    }),
    actions: {
      selectionChanged(newAdmin) {
        Ember.set(this, 'selectedAdmin', newAdmin);
        const business = this.business;

        if (Ember.isPresent(newAdmin)) {
          Ember.setProperties(business, {
            adminName: newAdmin.adminName,
            email: newAdmin.email,
            username: newAdmin.username
          });
        } else {
          business.setProperties({
            adminName: null,
            email: null,
            username: null
          });
        }
      }

    }
  });

  _exports.default = _default;
});