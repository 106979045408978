define("blackcat/users/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YExxW6rW",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[4,\"paper-subheader\",null,[[\"class\"],[\"md-no-sticky\"]],{\"statements\":[[0,\"Users\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"table\",true],[10,\"class\",\"flex\"],[8],[0,\"\\n  \"],[7,\"thead\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Name\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Email\"],[9],[0,\"\\n      \"],[7,\"th\",true],[10,\"class\",\"centered\"],[8],[0,\"Admin?\"],[9],[0,\"\\n      \"],[7,\"th\",true],[10,\"class\",\"table-actions\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"users\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"editable-user\",null,[[\"model\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"paper-subheader\",null,[[\"class\"],[\"md-no-sticky\"]],{\"statements\":[[0,\"New User\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"table\",true],[10,\"class\",\"flex\"],[8],[0,\"\\n  \"],[7,\"thead\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Name\"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"Email\"],[9],[0,\"\\n      \"],[7,\"th\",true],[10,\"class\",\"centered\"],[8],[0,\"Admin?\"],[9],[0,\"\\n      \"],[7,\"th\",true],[10,\"class\",\"table-actions\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n    \"],[1,[28,\"editable-user\",null,[[\"model\",\"userSaved\"],[[24,[\"newUser\"]],[28,\"route-action\",[\"setupNewUser\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "blackcat/users/template.hbs"
    }
  });

  _exports.default = _default;
});