define("blackcat/components/loading-content/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['layout', 'layout-wrap'],
    layout: 'row',
    'layout-wrap': 'true',
    // non-block form, when set, wrap defaultText property in <h2> tags
    defaultText: null
  });

  _exports.default = _default;
});