define("blackcat/mixins/cluster-autocomplete-mixin", ["exports", "blackcat/models/cluster", "ember-concurrency"], function (_exports, _cluster, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    tyAjax: Ember.inject.service('v1-ty'),

    init() {
      this._super(...arguments);

      if (Ember.isPresent(this.get('business.clusterId'))) {
        this.set('selectedCluster', new _cluster.default({
          clusterId: this.get('business.clusterId'),
          name: this.get('business.clusterName'),
          address: this.get('business.clusterAddress')
        }));
      } else {
        this.set('selectedCluster', null);
      }
    },

    searchClusters: (0, _emberConcurrency.task)(function* (filterText) {
      yield (0, _emberConcurrency.timeout)(300);

      if (Ember.isPresent(filterText) && filterText.length >= 3) {
        return this.tyAjax.clusterSearch(filterText);
      }

      return [];
    }),
    actions: {
      selectionChanged(newCluster) {
        this.set('selectedCluster', newCluster);
        const business = this.business;

        if (Ember.isPresent(newCluster)) {
          business.setProperties({
            clusterId: newCluster.clusterId,
            clusterName: newCluster.name,
            clusterAddress: newCluster.address
          });
        } else {
          business.setProperties({
            clusterId: null,
            clusterName: null,
            clusterAddress: null
          });
        }
      }

    }
  });

  _exports.default = _default;
});