define("blackcat/components/business-users-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['businessUsersList'],
    // Computed Properties
    users: Ember.computed.reads('model.users')
  });

  _exports.default = _default;
});