define("blackcat/adapters/application", ["exports", "blackcat/config/environment", "@ember-data/adapter/json-api", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _environment, _jsonApi, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HOST = _environment.default.APP.API_SERVER;

  var _default = _jsonApi.default.extend(_dataAdapterMixin.default, {
    host: HOST,
    namespace: 'internal',
    authorizer: 'authorizer:oauth2'
  });

  _exports.default = _default;
});