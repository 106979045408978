define("blackcat/businesses/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5BtS3JKG",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"business-new\",null,[[\"business\",\"transitionToBusiness\"],[[24,[\"model\"]],[28,\"route-action\",[\"transitionToBusiness\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "blackcat/businesses/new/template.hbs"
    }
  });

  _exports.default = _default;
});