define("blackcat/components/delete-business-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',

    init() {
      this._super(...arguments);

      this.setProperties({
        showConfirmation: false
      });
    },

    actions: {
      closeDeleteDialog(reason) {
        if (Ember.isEqual(reason, 'ok')) {
          this.model.destroyRecord().then(() => {
            this.toggleProperty('showConfirmation');
            this.sendAction('deleteAction');
          });
        } else {
          this.toggleProperty('showConfirmation');
        }
      },

      toggleShowConfirmation() {
        this.toggleProperty('showConfirmation');
      }

    }
  });

  _exports.default = _default;
});