define("blackcat/validators/alias", ["exports", "ember-cp-validations/validators/alias"], function (_exports, _alias) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _alias.default;
    }
  });
});