define("blackcat/validators/unique-resource", ["exports", "ember-cp-validations/validators/base", "blackcat/utils/string-utils"], function (_exports, _base, _stringUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    ajax: Ember.inject.service('v1-api'),

    validate(value, options, _model, attribute) {
      if (Ember.isEmpty(value)) {
        return true;
      }

      return this.ajax.request(this._buildURL(options), this._buildOptions(attribute, value)).then(() => {
        const errorMessage = Ember.isPresent(options.message) ? options.message : this._defaultMessage(attribute);
        return this.createErrorMessage('invalid', value, {
          message: errorMessage
        });
      }).catch(() => true);
    },

    _defaultMessage(attribute) {
      return `${attribute} has already been taken`;
    },

    _buildURL(options) {
      return `/${options.endpoint}`;
    },

    _buildOptions(attribute, value) {
      const options = {
        method: 'GET',
        data: {}
      };
      options.data[(0, _stringUtils.toSnakeCase)(attribute)] = value;
      return options;
    }

  });

  _exports.default = _default;
});