define("blackcat/authenticators/token", ["exports", "ember-simple-auth-token/authenticators/token", "jquery"], function (_exports, _token, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _token.default.extend({
    /**
    Returns an object used to be sent for authentication.
     Overwrote the data to return the format the server expects
     @method getAuthenticateData
    @return {object} An object with properties for authentication.
    @public
    */
    getAuthenticateData(credentials) {
      const formattedData = {};
      const attributes = {
        [this.passwordField]: credentials.password,
        [this.identificationField]: credentials.identification
      };
      formattedData.type = 'authentications';
      formattedData.attributes = attributes;
      return {
        data: formattedData
      };
    },

    /**
    Returns an object with properties the `authenticate` promise will resolve,
    be saved in and accessible via the session.
     @method getResponseData
    @return {object} An object with properties for the session.
    @public
    */
    getResponseData(response) {
      // only need the access token from the response and not the extra jsonapi data
      return response.data.attributes;
    },

    /**
    Overwrote the contentType sent to the server
     @method makeRequest
    @param {Object} data Object that will be sent to server
    @param {Object} headers Additional headers that will be sent to server
    @private
    */
    makeRequest(data, headers) {
      return _jquery.default.ajax({
        url: this.serverTokenEndpoint,
        method: 'POST',
        data: JSON.stringify(data),
        dataType: 'json',
        contentType: 'application/vnd.api+json',
        headers: this.headers,

        beforeSend(xhr, settings) {
          xhr.setRequestHeader('Accept', settings.accepts.json);

          if (headers) {
            Object.keys(headers).forEach(key => {
              xhr.setRequestHeader(key, headers[key]);
            });
          }
        }

      });
    }

  });

  _exports.default = _default;
});