define("blackcat/components/business-edit/component", ["exports", "blackcat/mixins/cluster-autocomplete-mixin", "blackcat/mixins/business-form-mixin", "blackcat/config/environment", "moment"], function (_exports, _clusterAutocompleteMixin, _businessFormMixin, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_clusterAutocompleteMixin.default, _businessFormMixin.default, {
    countryService: Ember.inject.service('country'),
    rootDomain: _environment.default.APP.APP_DOMAIN,
    updatedSubscription: null,

    /**
     * @public
     * Public properties accepted by business-edit
     * @param {Business} business - The business being edited
     */
    didReceiveAttrs() {
      this._super(...arguments);

      if (Ember.isPresent(this.get('business.countryCode'))) {
        const country = Ember.get(this, 'countryService.selectOptions').findBy('countryCode', Ember.get(this, 'business.countryCode'));
        Ember.set(this, 'selectedCountry', country);
      }
    },

    _saveBusinessRelation(resources) {
      const promises = [];

      if (Ember.isPresent(resources)) {
        resources.forEach(resource => {
          if (Object.keys(resource.changedAttributes()).length) {
            promises.push(resource.save());
          }
        });
      }

      return promises;
    },

    _successSaveSettingsAndChannels() {
      const business = this.business;
      Ember.set(this, 'isSaving', false);
      this.flashSuccess(() => {
        this.business.reload();
        this.transitionToBusiness(business.id);
      });
    },

    _errorSaveSettingsAndChannels(e) {
      let errorMessage = e;

      if (e.errors && e.errors[0].detail) {
        if (e.errors[0].detail === 'phone.phone_number is invalid') {
          errorMessage = 'Invalid phone number';
        } else {
          errorMessage = e.errors[0].detail;
        }
      }

      Ember.set(this, 'errorMessage', errorMessage);
    },

    actions: {
      dateChanged([newDate]) {
        // we don't want to set the trialEndDate on the initial
        // firing of this event by flatpickr because it'll make
        // the model dirty
        const currentDate = Ember.get(this, 'model.trialEndDate');
        const business = this.model;
        const updatedDate = (0, _moment.default)(newDate);

        if (Ember.isPresent(newDate) && !updatedDate.isSame(currentDate, 'day')) {
          Ember.set(business, 'selectedTrialEndDate', updatedDate.toDate());
          Ember.set(business, 'trialEndDate', updatedDate.toDate());
        }
      },

      save() {
        const business = this.business;
        const businessSettings = business.businessSettings;
        const businessChannels = business.businessChannels;
        const newSubscription = this.updatedSubscription;
        Ember.set(business, 'subscription', newSubscription);
        Ember.setProperties(this, {
          errorMessage: false,
          isSaving: true
        });
        business.validate().then(({
          validations
        }) => {
          if (validations.isValid) {
            business.save().then(() => {
              const promises = [...this._saveBusinessRelation(businessSettings), ...this._saveBusinessRelation(businessChannels)];
              return Ember.RSVP.all(promises);
            }).then(() => {
              this._successSaveSettingsAndChannels();
            }).catch(e => {
              this._errorSaveSettingsAndChannels(e);
            }).finally(() => {
              this.set('isSaving', false);
            });
          } else {
            Ember.setProperties(this, {
              errorMessage: validations.get('message'),
              isSaving: false
            });
          }
        });
      },

      cancel() {
        const business = this.business;
        const businessSettings = business.businessSettings;
        const businessChannels = business.businessChannels;
        business.rollbackAttributes();

        if (Ember.isPresent(businessSettings)) {
          businessSettings.forEach(setting => setting.rollbackAttributes());
        }

        if (Ember.isPresent(businessChannels)) {
          businessChannels.forEach(channel => {
            if (channel) {
              channel.rollbackAttributes();
            }
          });
        }

        this.business.reload();
        this.transitionToBusiness(business.id);
      }

    }
  });

  _exports.default = _default;
});