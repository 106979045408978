define("blackcat/utils/salesforceDataSerializer", ["exports", "blackcat/utils/parseEligibleChannels", "blackcat/utils/parseFloatToString"], function (_exports, _parseEligibleChannels, _parseFloatToString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /* eslint-disable camelcase */
  function _default(rawSalesforceData) {
    const {
      is_connected,
      name,
      number_of_rooms,
      billing_country,
      currency,
      invoicing_frequency,
      consumption_model_name,
      balance_value,
      wallet_threshold,
      salesforce_business_type,
      wallet_top_up_amount,
      error_message,
      ready_for_paid_channels,
      eligible_channels,
      next_invoicing_date
    } = rawSalesforceData;
    const parsedChannels = (0, _parseEligibleChannels.default)(eligible_channels);
    const salesforceData = {
      type: is_connected ? 'success' : 'error',
      name,
      number_of_rooms,
      billing_country,
      currency,
      consumption_model_name,
      invoicing_frequency,
      balance_value: (0, _parseFloatToString.default)(balance_value),
      wallet_threshold: (0, _parseFloatToString.default)(wallet_threshold),
      wallet_top_up_amount: (0, _parseFloatToString.default)(wallet_top_up_amount),
      salesforce_business_type,
      errors: error_message ? [{
        detail: error_message
      }] : null,
      ready_for_paid_channels,
      eligible_channels: parsedChannels,
      next_invoicing_date
    };
    return salesforceData;
  }
});