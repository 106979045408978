define("blackcat/authorizers/oauth2", ["exports", "ember-simple-auth/authorizers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    /**
      Includes the access token from the session data into the `Authorization`
      header as a Bearer token, e.g.:
      ```
      Authorization: Bearer 234rtgjneroigne4
      ```
      @method authorize
      @param {Object} data The data that the session currently holds
      @param {Function} block(headerName,headerContent) The callback to call with the authorization data; will receive the header name and header content as arguments
      @public
    */
    authorize(data, block) {
      const accessToken = data.access_token;

      if (!Ember.isEmpty(accessToken)) {
        block('Authorization', `Bearer ${accessToken}`);
      }
    }

  });

  _exports.default = _default;
});