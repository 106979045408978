define("blackcat/utils/parseFloatToString", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parseFloatToString;

  function parseFloatToString(value) {
    return value.toFixed(2);
  }
});