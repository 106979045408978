define("blackcat/services/ajax", ["exports", "ember-ajax/services/ajax", "blackcat/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    host: `${_environment.default.APP.API_SERVER}/internal`,
    headers: Ember.computed('session.authToken', 'session.data.authenticated.access_token', {
      // eslint-disable-line ember/use-brace-expansion
      get() {
        const headers = {};
        const accessToken = this.get('session.data.authenticated.access_token');

        if (!Ember.isBlank(accessToken)) {
          headers.Authorization = `Bearer ${accessToken}`;
        }

        return headers;
      }

    })
  });

  _exports.default = _default;
});