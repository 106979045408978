define("blackcat/industries/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ability: Ember.inject.service('can'),

    beforeModel() {
      this._super(...arguments);

      if (!this.ability.can('view industries')) {
        this.transitionTo('application');
      }
    },

    model() {
      return this.store.findAll('industry');
    },

    actions: {
      saveIndustry(industry) {
        if (industry.get('hasDirtyAttributes')) {
          industry.validate().then(({
            model,
            validations
          }) => {
            if (validations.get('isValid')) {
              model.save().then(() => {
                this.transitionTo('industries.index');
              });
            }
          });
        }
      },

      cancelIndustry(industry) {
        industry.rollbackAttributes();

        if (industry.get('isNew')) {
          industry.unloadRecord();
        }

        this.transitionTo('industries.index');
      }

    }
  });

  _exports.default = _default;
});