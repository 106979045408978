define("blackcat/components/settings-page/component", ["exports", "blackcat/mixins/error-messages-mixin"], function (_exports, _errorMessagesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_errorMessagesMixin.default, {
    init() {
      this._super(...arguments);

      this.setProperties({
        errorMessage: null,
        showSuccess: false
      });
    },

    // Computed Properties
    name: Ember.computed.oneWay('model.name'),
    password: Ember.computed.oneWay('model.password'),

    success() {
      this.set('showSuccess', true);
      Ember.run.later(this, function () {
        if (!this.isDestroyed) {
          this.set('showSuccess', false);
        }
      }, 2000);
    },

    actions: {
      save() {
        return new Ember.RSVP.Promise((resolve, reject) => {
          const user = this.model;
          user.set('name', this.name);

          if (Ember.isPresent(this.password)) {
            user.set('password', this.password);
          }

          user.validate().then(({
            validations
          }) => {
            if (!validations.get('isValid')) {
              this.set('errorMessage', validations.get('messages'));
              reject();
            } else {
              user.save().then(() => {
                this.success();
                this.set('password', null);
                resolve();
              }).catch(() => {
                this.set('errorMessage', this.errorMessages);
                reject();
              });
            }
          });
        });
      }

    }
  });

  _exports.default = _default;
});