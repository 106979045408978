define("blackcat/components/paper-item-no-click/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rt4a6Gen",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"action\"]]],null,{\"statements\":[[4,\"paper-button\",null,[[\"class\"],[\"md-no-style\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"md-list-item-inner\"],[8],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"md-no-style md-list-item-inner\"],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "blackcat/components/paper-item-no-click/template.hbs"
    }
  });

  _exports.default = _default;
});