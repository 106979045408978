define("blackcat/mirage/factories/business", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.default.Factory.extend({
    id() {
      return _emberCliMirage.faker.random.uuid();
    },

    name: _emberCliMirage.faker.company.companyName(),
    subdomain: _emberCliMirage.faker.lorem.word(),
    subscription_active: true,
    room_count: _emberCliMirage.faker.random.number(100),
    cluster_id: _emberCliMirage.faker.random.uuid(),
    is_active: true,
    scale: 100
  });

  _exports.default = _default;
});