define("blackcat/ping/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dsxVhsoV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[8],[0,\"PONG\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "blackcat/ping/template.hbs"
    }
  });

  _exports.default = _default;
});