define("blackcat/mixins/business-form-mixin", ["exports", "moment", "blackcat/constants/business"], function (_exports, _moment, _business) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    modifiedBusinessSettings: Object.freeze({}),
    allChannelsDisabled: Ember.computed.empty('selectedSubscription'),
    channelsInputs: _business.default.channelsInputs,
    currentBusiness: Ember.computed.alias('business'),
    activeChannels: Ember.computed.reads('selectedSubscription.modifiable_channels'),
    inactiveChannels: Ember.computed('activeChannels', 'channelsInputs', function () {
      return this.channelsInputs.filter(channel => !this.activeChannels.includes(channel));
    }),
    onChannels: Ember.computed.reads('selectedSubscription.default_channels_enabled'),
    offChannels: Ember.computed('channelsInputs', 'selectedSubscription.{default_channels_enabled,name}', function () {
      // eslint-disable-next-line max-len
      return this.channelsInputs.filter(channel => !this.selectedSubscription.default_channels_enabled.includes(channel));
    }),
    isSubscriptionActive: Ember.computed.alias('business.subscriptionActive'),
    isTrusted: Ember.computed.or('isSubscriptionActive', 'business.settingsMap.trusted'),
    internalAccount: Ember.computed.reads('business.settingsMap.internalAccount'),
    accountType: Ember.computed('business.settingsMap.internalAccount', function () {
      if (this.business.settingsMap.internalAccount) {
        return 'Internal';
      }

      return 'Customer';
    }),
    salesforceBusinessType: Ember.computed('business.salesforceBusinessType', function () {
      return this.business.salesforceBusinessType ? this.business.salesforceBusinessType : 'Customer';
    }),
    importPhoneNumberFormat: Ember.computed('business.settingsMap.importPhoneNumberFormat', function () {
      return this.business.settingsMap.importPhoneNumberFormat === undefined ? _business.default.defaultOptions.DEFAULT_IMPORT_PHONE_FORMAT : this.business.settingsMap.importPhoneNumberFormat;
    }),
    importDateEndianness: Ember.computed('business.settingsMap.importDateEndianness', function () {
      return this.business.settingsMap.importDateEndianness === undefined ? _business.default.defaultOptions.DEFAULT_IMPORT_DATE_ENDIANNESS : this.business.settingsMap.importDateEndianness;
    }),
    businessStatus: Ember.computed('business.{subscriptionActive,trialEndDate}', function () {
      if (this.business.trialEndDate) {
        return null;
      }

      if (this.business.subscriptionActive) {
        return 'active';
      }

      return 'inactive';
    }),
    shouldDisabledTrial: Ember.computed('isEditing', 'business.subscriptionActive', 'selectedTrialEndDate', 'unlockInEdition', function () {
      const isEditingAndIsActive = this.isEditing && this.business.subscriptionActive;

      if (isEditingAndIsActive && !this.unlockInEdition) {
        return true;
      }

      return false;
    }),

    flashSuccess(afterSuccess) {
      this.set('showSuccess', true);
      Ember.run.later(this, function () {
        this.set('showSuccess', false);

        if (Ember.isPresent(afterSuccess)) {
          afterSuccess();
        }
      }, 1000);
    },

    _updateBusinessSetting(name, value) {
      const business = this.business;
      let foundSetting = business.businessSettings.find(setting => setting.name === name);
      Ember.set(this, name, value);

      if (foundSetting) {
        Ember.set(foundSetting, 'value', value.toString());
      } else {
        foundSetting = this.store.createRecord('business-setting', {
          name,
          value,
          business
        });
      }
    },

    _setChannelForBusinessSignUp(booleanValue, channelName) {
      const businessChannels = this.business.channels;
      let finalChannelsToSet = [channelName];

      if (!booleanValue && businessChannels) {
        finalChannelsToSet = businessChannels.filter(channel => channel !== channelName);
      } else if (businessChannels) {
        finalChannelsToSet = [...businessChannels, channelName];
      }

      Ember.set(this, 'business.channels', finalChannelsToSet);
    },

    _setChannelForBusiness(value, business, channelName) {
      this.store.createRecord('business-channel', {
        name: channelName,
        value,
        business
      });
    },

    setChannel(channelName, value, isInitialLoading) {
      let booleanValue = value;

      if (typeof value === 'string') {
        booleanValue = value !== 'false';
      }

      if (isInitialLoading) {
        Ember.set(this, `${channelName}ChannelValue`, booleanValue);
      } else {
        Ember.set(this, `${channelName}ChannelValue`, !Ember.get(this, `${channelName}ChannelValue`));
      }

      const business = this.business;
      let channel;

      if (business.businessChannels) {
        channel = business.businessChannels.find(ch => ch.name === channelName);
      }

      const modelName = business.constructor.modelName;

      if (channel) {
        Ember.set(channel, 'value', value.toString());
      } else if (modelName === 'business-signup') {
        this._setChannelForBusinessSignUp(booleanValue, channelName);
      } else {
        this._setChannelForBusiness(value, business, channelName);
      }
    },

    _setValuesBasedOnSalesforceEligibles(key, isModifiableButNotEligible) {
      const keepOriginalValue = true;

      if (isModifiableButNotEligible) {
        Ember.set(this, `${key}Disabled`, true);
        Ember.set(this, `${key}Warning`, true);
        this.setChannel(key, false, keepOriginalValue);
      } else {
        Ember.set(this, `${key}Warning`, false);
      }
    },

    _checkSalesforceEligibleChannels(subscription) {
      const eligibleSalesforceChannels = this.salesforce.eligible_channels;
      this.channelsInputs.forEach(key => {
        // eslint-disable-next-line ember/no-get
        const includedByModifiableChannels = Ember.get(subscription, 'modifiable_channels').includes(key);
        const isModifiableButNotEligible = !eligibleSalesforceChannels.includes(key) && includedByModifiableChannels;

        this._setValuesBasedOnSalesforceEligibles(key, isModifiableButNotEligible);
      });
    },

    _iterateAndSetChannels(channels, keyToSet, attribute, valueToSet) {
      if (channels && channels.includes(keyToSet)) {
        Ember.set(this, `${keyToSet}${attribute}`, valueToSet);
      }
    },

    _setChannelsValuesForPreviousChannels(previousChannels) {
      const isInitialLoading = true;
      previousChannels.forEach(channel => {
        const channelName = channel.name;
        this.setChannel(channelName, channel.value, isInitialLoading);

        this._iterateAndSetChannels(this.activeChannels, channelName, 'Disabled', false);
      });
      this.channelsInputs.forEach(key => {
        this._iterateAndSetChannels(this.inactiveChannels, key, 'Disabled', true);
      });
    },

    _setChannelsValues() {
      this.onChannels.forEach(channel => this.setChannel(channel, true));
      this.offChannels.forEach(channel => this.setChannel(channel, false));
      this.channelsInputs.forEach(key => {
        this._iterateAndSetChannels(this.onChannels, key, 'ChannelValue', true);

        this._iterateAndSetChannels(this.offChannels, key, 'ChannelValue', false);

        this._iterateAndSetChannels(this.activeChannels, key, 'Disabled', false);

        this._iterateAndSetChannels(this.inactiveChannels, key, 'Disabled', true);
      });
    },

    setSubscription(subscription, previousChannels) {
      const modelName = this.business.constructor.modelName;

      if (modelName === 'business-signup') {
        Ember.set(this, 'business.channels', []);
      }

      Ember.set(this, 'selectedSubscription', subscription);

      if (this.isEditing) {
        Ember.set(this, 'updatedSubscription', subscription);
      }

      if (Ember.isPresent(previousChannels)) {
        this._setChannelsValuesForPreviousChannels(previousChannels);
      } else {
        this._setChannelsValues();
      }

      this._checkSalesforceEligibleChannels(subscription);
    },

    actions: {
      updateBusinessSetting(name, value) {
        this._updateBusinessSetting(name, value);
      },

      updateChannelSelection(channelName, value) {
        this.setChannel(channelName, value);
      },

      setSelectedBusinessType(selection) {
        if (selection === 'Internal') {
          this._updateBusinessSetting('internalAccount', true);
        } else {
          this._updateBusinessSetting('internalAccount', false);
        }
      },

      setSelectedSalesforceBusinessType(selection) {
        Ember.set(this, 'business.salesforceBusinessType', selection);
      },

      setSelectedCountry(selection) {
        let countryCode;

        if (Ember.isPresent(selection)) {
          countryCode = selection.countryCode;
        }

        Ember.setProperties(this, {
          selectedCountry: selection,
          'business.countryCode': countryCode
        });
      },

      changeStatus(status) {
        Ember.set(this, 'business.subscriptionActive', status);
      },

      setTrial(value) {
        const business = this.business;

        if (!value) {
          Ember.set(business, 'trial', false);
          Ember.set(this, 'selectedTrialEndDate', null);
          Ember.set(business, 'trialEndDate', null);
          Ember.set(this, 'unlockInEdition', this.isEditing);
        } else {
          const newDate = (0, _moment.default)().add(14, 'days');
          Ember.set(this, 'selectedTrialEndDate', newDate.format('MMMM.DD.YYYY'));
          Ember.set(business, 'trial', true);
          Ember.set(business, 'trialEndDate', newDate);

          if (this.isEditing) {
            Ember.set(business, 'subscriptionActive', false);
          }
        }
      }

    }
  });

  _exports.default = _default;
});