define("blackcat/application/route", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service(),

    model() {
      return this.get('session.currentUser');
    },

    title(tokens) {
      if (Ember.isPresent(tokens)) {
        return tokens.join(' - ');
      }

      return 'Home';
    },

    actions: {
      transitionTo(routeName) {
        this.transitionTo(routeName);
      },

      logOut() {
        this.session.invalidate();
      }

    }
  });

  _exports.default = _default;
});