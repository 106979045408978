define("blackcat/models/business", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    countryCode: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      dependentKeys: ['phoneNumber'],
      disabled: Ember.computed('model.phoneNumber', function () {
        return !Ember.isPresent(Ember.get(this, 'model.phoneNumber'));
      }),
      description: 'Country code'
    })],
    phoneNumber: [(0, _emberCpValidations.validator)('format', {
      regex: /^[^a-zA-z]*$/,
      allowBlank: true,
      message: 'Phone number must be valid',
      description: 'Phone number'
    })]
  });

  var _default = _model.default.extend(Validations, {
    // eslint-disable-line array-callback-return
    name: (0, _model.attr)('string'),
    subdomain: (0, _model.attr)('string'),
    schema: (0, _model.attr)('json'),
    users: (0, _model.hasMany)('user'),
    integrations: (0, _model.hasMany)('integration'),
    trialEndDate: (0, _model.attr)('utc'),
    isActive: (0, _model.attr)('boolean'),
    subscriptionActive: (0, _model.attr)('boolean'),
    roomCount: (0, _model.attr)('number'),
    clusterId: (0, _model.attr)('string'),
    clusterName: (0, _model.attr)('string'),
    clusterAddress: (0, _model.attr)('string'),
    phoneNumber: (0, _model.attr)('string'),
    countryCode: (0, _model.attr)('string'),
    salesforceData: (0, _model.attr)('json'),
    businessSettings: (0, _model.hasMany)('businessSetting', {
      async: false,
      inverse: 'business'
    }),
    subscription: (0, _model.belongsTo)('subscription'),
    balance: (0, _model.belongsTo)('balance'),
    businessChannels: (0, _model.hasMany)('businessChannel', {
      inverse: 'business'
    }),
    activeUsers: Ember.computed.filterBy('users', 'isDeleted', false),
    inactiveUsers: Ember.computed.filterBy('users', 'isDeleted', true),
    schemaString: Ember.computed('schema', function () {
      return JSON.stringify(this.schema, null, 2);
    }),
    settingsMap: Ember.computed('businessSettings.@each.value', function () {
      // Try to guess value type and parse it
      const parseValue = value => {
        if (value === 'false') {
          return false;
        }

        if (value === 'true') {
          return true;
        }

        if (!Number.isNaN(+value)) {
          return +value;
        }

        return value;
      };

      return this.businessSettings.toArray().reduce((acc, p) => ({ ...acc,
        [p.get('name')]: parseValue(p.get('value'))
      }), {});
    }),
    mappedActiveChannels: Ember.computed('businessChannels.@each.value', function () {
      const capitalize = str => `${str[0].toUpperCase()}${str.slice(1)}`;

      const filtered = [];
      this.businessChannels.forEach(channel => {
        if (channel.value === 'true') {
          filtered.push({
            name: capitalize(channel.name),
            value: channel.value
          });
        }
      });
      return filtered;
    })
  });

  _exports.default = _default;
});