define("blackcat/components/menu-item/component", ["exports", "ember-paper/components/paper-item", "ember-cli-active-link-wrapper/mixins/active-link"], function (_exports, _paperItem, _activeLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperItem.default.extend(_activeLink.default, {
    classNames: 'menu-item',
    layoutName: 'components/paper-item',
    activeClass: 'active'
  });

  _exports.default = _default;
});