define("blackcat/users/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    users: Ember.computed('model.@each.isNew', function () {
      return this.model.filter(user => !user.get('isNew'));
    }),
    newUser: Ember.computed.alias('model.newUser')
  });

  _exports.default = _default;
});