define("blackcat/components/product-information/component", ["exports", "blackcat/mixins/business-form-mixin"], function (_exports, _businessFormMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const USERS_LIMIT_STEP_SIZE = 30;
  const initialUsersCountLimitOptions = Array.from({
    length: 10
  }, (x, i) => ({
    label: i + 1,
    value: String(USERS_LIMIT_STEP_SIZE * (i + 1))
  }));

  var _default = Ember.Component.extend(_businessFormMixin.default, {
    init() {
      this._super(...arguments);

      const business = this.business;

      if (this.isEditing) {
        Ember.set(this, 'usersCountLimitOptions', initialUsersCountLimitOptions);
        const channels = business.businessChannels;
        const countLimitSetting = business.businessSettings.find(setting => setting.name === 'userCountLimit');
        this.setSubscription(this.selectedSubscription, channels);
        const parsedCountLimitSetting = {
          label: Number(countLimitSetting.value) / USERS_LIMIT_STEP_SIZE,
          value: countLimitSetting.value
        };
        Ember.set(this, 'selectedUsersCountLimit', parsedCountLimitSetting);
      }
    },

    didDestroyElement() {
      this._super(...arguments);

      if (!this.isEditing) {
        Ember.set(this, 'selectedSubscription', null);
      }
    },

    actions: {
      selectSubscription(subscription) {
        this.setSubscription(subscription);

        if (this.changeSubscriptionCallback) {
          this.changeSubscriptionCallback(subscription);
        }
      },

      handleSelectUsersLimit(selection) {
        Ember.set(this, 'selectedUsersCountLimit', selection);

        this._updateBusinessSetting('userCountLimit', selection.value);
      }

    }
  });

  _exports.default = _default;
});