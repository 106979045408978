define("blackcat/components/confirm-action/component", ["exports", "ember-paper/components/paper-button"], function (_exports, _paperButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperButton.default.extend({
    confirming: false,
    confirmingLoop: null,
    bubbles: false,

    click() {
      if (this.confirming) {
        Ember.run.cancel(this.confirmingLoop);
        return this._super();
      }

      this.set('confirming', true);
      const clearConfirming = Ember.run.later(this, function () {
        if (this.isVisible && this.confirming) {
          this.set('confirming', false);
        }
      }, 5000);
      this.set('confirmingLoop', clearConfirming);
      return false;
    }

  });

  _exports.default = _default;
});