define("blackcat/services/session", ["exports", "ember-simple-auth/services/session", "jwt-decode"], function (_exports, _session, _jwtDecode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _session.default.extend({
    store: Ember.inject.service(),
    currentUser: Ember.computed('isAuthenticated', 'data.authenticated.access_token', function () {
      if (this.isAuthenticated) {
        const currentUserId = this.idFromToken();
        return currentUserId ? this.store.find('internalUser', currentUserId) : null;
      }

      return null;
    }),

    idFromToken() {
      const token = this.get('data.authenticated.access_token');

      if (Ember.isPresent(token)) {
        return (0, _jwtDecode.default)(token).sub;
      }

      return null;
    }

  });

  _exports.default = _default;
});