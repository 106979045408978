define("blackcat/components/subscription-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service('store'),

    init() {
      this._super(...arguments);

      this.store.findAll('subscription').then(subscriptions => Ember.set(this, 'subscriptions', subscriptions));
    },

    actions: {
      handleChange(subscription) {
        this.selectSubscription(subscription);
      }

    }
  });

  _exports.default = _default;
});