define("blackcat/industries/show/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    disableSaveButton: Ember.computed('model.{isInvalid,hasDirtyAttributes}', function () {
      return this.get('model.isInvalid') || !this.get('model.hasDirtyAttributes');
    })
  });

  _exports.default = _default;
});