define("blackcat/mixins/business-presenter-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    subscriptionStatus: Ember.computed('model.subscriptionActive', function () {
      return this.get('model.subscriptionActive') ? 'Active' : 'Inactive';
    }),
    roomCountDisplay: Ember.computed('model.roomCount', function () {
      const roomCount = this.get('model.roomCount');

      if (Ember.isPresent(roomCount)) {
        return roomCount;
      }

      return 'Unknown';
    }),
    clusterDisplay: Ember.computed('business.{clusterAddress,clusterId,clusterName}', 'model.{clusterAddress,clusterId,clusterName}', function () {
      const clusterId = this.get('model.clusterId');
      const clusterIdBusiness = this.get('business.clusterId');

      if (Ember.isPresent(clusterId) || Ember.isPresent(clusterIdBusiness)) {
        if (clusterId) {
          return `${this.get('model.clusterName')} - ${this.get('model.clusterAddress')} (${clusterId})`;
        }

        return `${this.get('business.clusterName')} - ${this.get('business.clusterAddress')} (${clusterId})`;
      }

      return 'Not connected to a TrustYou Cluster.';
    }),
    trustedDisplay: Ember.computed('model.settingsMap.trusted', 'model.subscriptionActive', function () {
      // eslint-disable-line ember/use-brace-expansion
      return this.get('model.subscriptionActive') || this.get('model.settingsMap.trusted') ? 'Yes' : 'No';
    }),
    chatbotEnabledDisplay: Ember.computed('model.settingsMap.chatbot', function () {
      return this.get('model.settingsMap.chatbot') ? 'Yes' : 'No';
    }),
    subscriptionType: Ember.computed.alias('model.subscription'),
    balance: Ember.computed('model.balance.{amount,currency}', function () {
      return {
        amount: Ember.get(this, 'model.balance.amount') * 100,
        currency: Ember.get(this, 'model.balance.currency')
      };
    })
  });

  _exports.default = _default;
});