define("blackcat/services/country", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Object.freeze({
    selectOptions: [{
      countryCode: 'AF',
      name: 'AF - Afghanistan'
    }, {
      countryCode: 'AX',
      name: 'AX - Aland Islands'
    }, {
      countryCode: 'AL',
      name: 'AL - Albania'
    }, {
      countryCode: 'DZ',
      name: 'DZ - Algeria'
    }, {
      countryCode: 'AS',
      name: 'AS - American Samoa'
    }, {
      countryCode: 'AD',
      name: 'AD - Andorra'
    }, {
      countryCode: 'AO',
      name: 'AO - Angola'
    }, {
      countryCode: 'AI',
      name: 'AI - Anguilla'
    }, {
      countryCode: 'AQ',
      name: 'AQ - Antarctica'
    }, {
      countryCode: 'AG',
      name: 'AG - Antigua And Barbuda'
    }, {
      countryCode: 'AR',
      name: 'AR - Argentina'
    }, {
      countryCode: 'AM',
      name: 'AM - Armenia'
    }, {
      countryCode: 'AW',
      name: 'AW - Aruba'
    }, {
      countryCode: 'AU',
      name: 'AU - Australia'
    }, {
      countryCode: 'AT',
      name: 'AT - Austria'
    }, {
      countryCode: 'AZ',
      name: 'AZ - Azerbaijan'
    }, {
      countryCode: 'BS',
      name: 'BS - Bahamas'
    }, {
      countryCode: 'BH',
      name: 'BH - Bahrain'
    }, {
      countryCode: 'BD',
      name: 'BD - Bangladesh'
    }, {
      countryCode: 'BB',
      name: 'BB - Barbados'
    }, {
      countryCode: 'BY',
      name: 'BY - Belarus'
    }, {
      countryCode: 'BE',
      name: 'BE - Belgium'
    }, {
      countryCode: 'BZ',
      name: 'BZ - Belize'
    }, {
      countryCode: 'BJ',
      name: 'BJ - Benin'
    }, {
      countryCode: 'BM',
      name: 'BM - Bermuda'
    }, {
      countryCode: 'BT',
      name: 'BT - Bhutan'
    }, {
      countryCode: 'BO',
      name: 'BO - Bolivia'
    }, {
      countryCode: 'BA',
      name: 'BA - Bosnia And Herzegovina'
    }, {
      countryCode: 'BW',
      name: 'BW - Botswana'
    }, {
      countryCode: 'BV',
      name: 'BV - Bouvet Island'
    }, {
      countryCode: 'BR',
      name: 'BR - Brazil'
    }, {
      countryCode: 'IO',
      name: 'IO - British Indian Ocean Territory'
    }, {
      countryCode: 'BN',
      name: 'BN - Brunei Darussalam'
    }, {
      countryCode: 'BG',
      name: 'BG - Bulgaria'
    }, {
      countryCode: 'BF',
      name: 'BF - Burkina Faso'
    }, {
      countryCode: 'BI',
      name: 'BI - Burundi'
    }, {
      countryCode: 'KH',
      name: 'KH - Cambodia'
    }, {
      countryCode: 'CM',
      name: 'CM - Cameroon'
    }, {
      countryCode: 'CA',
      name: 'CA - Canada'
    }, {
      countryCode: 'CV',
      name: 'CV - Cape Verde'
    }, {
      countryCode: 'KY',
      name: 'KY - Cayman Islands'
    }, {
      countryCode: 'CF',
      name: 'CF - Central African Republic'
    }, {
      countryCode: 'TD',
      name: 'TD - Chad'
    }, {
      countryCode: 'CL',
      name: 'CL - Chile'
    }, {
      countryCode: 'CN',
      name: 'CN - China'
    }, {
      countryCode: 'CX',
      name: 'CX - Christmas Island'
    }, {
      countryCode: 'CC',
      name: 'CC - Cocos (Keeling) Islands'
    }, {
      countryCode: 'CO',
      name: 'CO - Colombia'
    }, {
      countryCode: 'KM',
      name: 'KM - Comoros'
    }, {
      countryCode: 'CG',
      name: 'CG - Congo'
    }, {
      countryCode: 'CD',
      name: 'CD - Congo, Democratic Republic'
    }, {
      countryCode: 'CK',
      name: 'CK - Cook Islands'
    }, {
      countryCode: 'CR',
      name: 'CR - Costa Rica'
    }, {
      countryCode: 'CI',
      name: 'CI - Cote D\'Ivoire'
    }, {
      countryCode: 'HR',
      name: 'HR - Croatia'
    }, {
      countryCode: 'CU',
      name: 'CU - Cuba'
    }, {
      countryCode: 'CY',
      name: 'CY - Cyprus'
    }, {
      countryCode: 'CZ',
      name: 'CZ - Czech Republic'
    }, {
      countryCode: 'DK',
      name: 'DK - Denmark'
    }, {
      countryCode: 'DJ',
      name: 'DJ - Djibouti'
    }, {
      countryCode: 'DM',
      name: 'DM - Dominica'
    }, {
      countryCode: 'DO',
      name: 'DO - Dominican Republic'
    }, {
      countryCode: 'EC',
      name: 'EC - Ecuador'
    }, {
      countryCode: 'EG',
      name: 'EG - Egypt'
    }, {
      countryCode: 'SV',
      name: 'SV - El Salvador'
    }, {
      countryCode: 'GQ',
      name: 'GQ - Equatorial Guinea'
    }, {
      countryCode: 'ER',
      name: 'ER - Eritrea'
    }, {
      countryCode: 'EE',
      name: 'EE - Estonia'
    }, {
      countryCode: 'ET',
      name: 'ET - Ethiopia'
    }, {
      countryCode: 'FK',
      name: 'FK - Falkland Islands (Malvinas)'
    }, {
      countryCode: 'FO',
      name: 'FO - Faroe Islands'
    }, {
      countryCode: 'FJ',
      name: 'FJ - Fiji'
    }, {
      countryCode: 'FI',
      name: 'FI - Finland'
    }, {
      countryCode: 'FR',
      name: 'FR - France'
    }, {
      countryCode: 'GF',
      name: 'GF - French Guiana'
    }, {
      countryCode: 'PF',
      name: 'PF - French Polynesia'
    }, {
      countryCode: 'TF',
      name: 'TF - French Southern Territories'
    }, {
      countryCode: 'GA',
      name: 'GA - Gabon'
    }, {
      countryCode: 'GM',
      name: 'GM - Gambia'
    }, {
      countryCode: 'GE',
      name: 'GE - Georgia'
    }, {
      countryCode: 'DE',
      name: 'DE - Germany'
    }, {
      countryCode: 'GH',
      name: 'GH - Ghana'
    }, {
      countryCode: 'GI',
      name: 'GI - Gibraltar'
    }, {
      countryCode: 'GR',
      name: 'GR - Greece'
    }, {
      countryCode: 'GL',
      name: 'GL - Greenland'
    }, {
      countryCode: 'GD',
      name: 'GD - Grenada'
    }, {
      countryCode: 'GP',
      name: 'GP - Guadeloupe'
    }, {
      countryCode: 'GU',
      name: 'GU - Guam'
    }, {
      countryCode: 'GT',
      name: 'GT - Guatemala'
    }, {
      countryCode: 'GG',
      name: 'GG - Guernsey'
    }, {
      countryCode: 'GN',
      name: 'GN - Guinea'
    }, {
      countryCode: 'GW',
      name: 'GW - Guinea-Bissau'
    }, {
      countryCode: 'GY',
      name: 'GY - Guyana'
    }, {
      countryCode: 'HT',
      name: 'HT - Haiti'
    }, {
      countryCode: 'HM',
      name: 'HM - Heard Island & Mcdonald Islands'
    }, {
      countryCode: 'VA',
      name: 'VA - Holy See (Vatican City State)'
    }, {
      countryCode: 'HN',
      name: 'HN - Honduras'
    }, {
      countryCode: 'HK',
      name: 'HK - Hong Kong'
    }, {
      countryCode: 'HU',
      name: 'HU - Hungary'
    }, {
      countryCode: 'IS',
      name: 'IS - Iceland'
    }, {
      countryCode: 'IN',
      name: 'IN - India'
    }, {
      countryCode: 'ID',
      name: 'ID - Indonesia'
    }, {
      countryCode: 'IR',
      name: 'IR - Iran, Islamic Republic Of'
    }, {
      countryCode: 'IQ',
      name: 'IQ - Iraq'
    }, {
      countryCode: 'IE',
      name: 'IE - Ireland'
    }, {
      countryCode: 'IM',
      name: 'IM - Isle Of Man'
    }, {
      countryCode: 'IL',
      name: 'IL - Israel'
    }, {
      countryCode: 'IT',
      name: 'IT - Italy'
    }, {
      countryCode: 'JM',
      name: 'JM - Jamaica'
    }, {
      countryCode: 'JP',
      name: 'JP - Japan'
    }, {
      countryCode: 'JE',
      name: 'JE - Jersey'
    }, {
      countryCode: 'JO',
      name: 'JO - Jordan'
    }, {
      countryCode: 'KZ',
      name: 'KZ - Kazakhstan'
    }, {
      countryCode: 'KE',
      name: 'KE - Kenya'
    }, {
      countryCode: 'KI',
      name: 'KI - Kiribati'
    }, {
      countryCode: 'KR',
      name: 'KR - Korea'
    }, {
      countryCode: 'KW',
      name: 'KW - Kuwait'
    }, {
      countryCode: 'KG',
      name: 'KG - Kyrgyzstan'
    }, {
      countryCode: 'LA',
      name: 'LA - Lao People\'s Democratic Republic'
    }, {
      countryCode: 'LV',
      name: 'LV - Latvia'
    }, {
      countryCode: 'LB',
      name: 'LB - Lebanon'
    }, {
      countryCode: 'LS',
      name: 'LS - Lesotho'
    }, {
      countryCode: 'LR',
      name: 'LR - Liberia'
    }, {
      countryCode: 'LY',
      name: 'LY - Libyan Arab Jamahiriya'
    }, {
      countryCode: 'LI',
      name: 'LI - Liechtenstein'
    }, {
      countryCode: 'LT',
      name: 'LT - Lithuania'
    }, {
      countryCode: 'LU',
      name: 'LU - Luxembourg'
    }, {
      countryCode: 'MO',
      name: 'MO - Macao'
    }, {
      countryCode: 'MK',
      name: 'MK - Macedonia'
    }, {
      countryCode: 'MG',
      name: 'MG - Madagascar'
    }, {
      countryCode: 'MW',
      name: 'MW - Malawi'
    }, {
      countryCode: 'MY',
      name: 'MY - Malaysia'
    }, {
      countryCode: 'MV',
      name: 'MV - Maldives'
    }, {
      countryCode: 'ML',
      name: 'ML - Mali'
    }, {
      countryCode: 'MT',
      name: 'MT - Malta'
    }, {
      countryCode: 'MH',
      name: 'MH - Marshall Islands'
    }, {
      countryCode: 'MQ',
      name: 'MQ - Martinique'
    }, {
      countryCode: 'MR',
      name: 'MR - Mauritania'
    }, {
      countryCode: 'MU',
      name: 'MU - Mauritius'
    }, {
      countryCode: 'YT',
      name: 'YT - Mayotte'
    }, {
      countryCode: 'MX',
      name: 'MX - Mexico'
    }, {
      countryCode: 'FM',
      name: 'FM - Micronesia, Federated States Of'
    }, {
      countryCode: 'MD',
      name: 'MD - Moldova'
    }, {
      countryCode: 'MC',
      name: 'MC - Monaco'
    }, {
      countryCode: 'MN',
      name: 'MN - Mongolia'
    }, {
      countryCode: 'ME',
      name: 'ME - Montenegro'
    }, {
      countryCode: 'MS',
      name: 'MS - Montserrat'
    }, {
      countryCode: 'MA',
      name: 'MA - Morocco'
    }, {
      countryCode: 'MZ',
      name: 'MZ - Mozambique'
    }, {
      countryCode: 'MM',
      name: 'MM - Myanmar'
    }, {
      countryCode: 'NA',
      name: 'NA - Namibia'
    }, {
      countryCode: 'NR',
      name: 'NR - Nauru'
    }, {
      countryCode: 'NP',
      name: 'NP - Nepal'
    }, {
      countryCode: 'NL',
      name: 'NL - Netherlands'
    }, {
      countryCode: 'AN',
      name: 'AN - Netherlands Antilles'
    }, {
      countryCode: 'NC',
      name: 'NC - New Caledonia'
    }, {
      countryCode: 'NZ',
      name: 'NZ - New Zealand'
    }, {
      countryCode: 'NI',
      name: 'NI - Nicaragua'
    }, {
      countryCode: 'NE',
      name: 'NE - Niger'
    }, {
      countryCode: 'NG',
      name: 'NG - Nigeria'
    }, {
      countryCode: 'NU',
      name: 'NU - Niue'
    }, {
      countryCode: 'NF',
      name: 'NF - Norfolk Island'
    }, {
      countryCode: 'MP',
      name: 'MP - Northern Mariana Islands'
    }, {
      countryCode: 'NO',
      name: 'NO - Norway'
    }, {
      countryCode: 'OM',
      name: 'OM - Oman'
    }, {
      countryCode: 'PK',
      name: 'PK - Pakistan'
    }, {
      countryCode: 'PW',
      name: 'PW - Palau'
    }, {
      countryCode: 'PS',
      name: 'PS - Palestinian Territory, Occupied'
    }, {
      countryCode: 'PA',
      name: 'PA - Panama'
    }, {
      countryCode: 'PG',
      name: 'PG - Papua New Guinea'
    }, {
      countryCode: 'PY',
      name: 'PY - Paraguay'
    }, {
      countryCode: 'PE',
      name: 'PE - Peru'
    }, {
      countryCode: 'PH',
      name: 'PH - Philippines'
    }, {
      countryCode: 'PN',
      name: 'PN - Pitcairn'
    }, {
      countryCode: 'PL',
      name: 'PL - Poland'
    }, {
      countryCode: 'PT',
      name: 'PT - Portugal'
    }, {
      countryCode: 'PR',
      name: 'PR - Puerto Rico'
    }, {
      countryCode: 'QA',
      name: 'QA - Qatar'
    }, {
      countryCode: 'RE',
      name: 'RE - Reunion'
    }, {
      countryCode: 'RO',
      name: 'RO - Romania'
    }, {
      countryCode: 'RU',
      name: 'RU - Russian Federation'
    }, {
      countryCode: 'RW',
      name: 'RW - Rwanda'
    }, {
      countryCode: 'BL',
      name: 'BL - Saint Barthelemy'
    }, {
      countryCode: 'SH',
      name: 'SH - Saint Helena'
    }, {
      countryCode: 'KN',
      name: 'KN - Saint Kitts And Nevis'
    }, {
      countryCode: 'LC',
      name: 'LC - Saint Lucia'
    }, {
      countryCode: 'MF',
      name: 'MF - Saint Martin'
    }, {
      countryCode: 'PM',
      name: 'PM - Saint Pierre And Miquelon'
    }, {
      countryCode: 'VC',
      name: 'VC - Saint Vincent And Grenadines'
    }, {
      countryCode: 'WS',
      name: 'WS - Samoa'
    }, {
      countryCode: 'SM',
      name: 'SM - San Marino'
    }, {
      countryCode: 'ST',
      name: 'ST - Sao Tome And Principe'
    }, {
      countryCode: 'SA',
      name: 'SA - Saudi Arabia'
    }, {
      countryCode: 'SN',
      name: 'SN - Senegal'
    }, {
      countryCode: 'RS',
      name: 'RS - Serbia'
    }, {
      countryCode: 'SC',
      name: 'SC - Seychelles'
    }, {
      countryCode: 'SL',
      name: 'SL - Sierra Leone'
    }, {
      countryCode: 'SG',
      name: 'SG - Singapore'
    }, {
      countryCode: 'SK',
      name: 'SK - Slovakia'
    }, {
      countryCode: 'SI',
      name: 'SI - Slovenia'
    }, {
      countryCode: 'SB',
      name: 'SB - Solomon Islands'
    }, {
      countryCode: 'SO',
      name: 'SO - Somalia'
    }, {
      countryCode: 'ZA',
      name: 'ZA - South Africa'
    }, {
      countryCode: 'GS',
      name: 'GS - South Georgia And Sandwich Isl.'
    }, {
      countryCode: 'ES',
      name: 'ES - Spain'
    }, {
      countryCode: 'LK',
      name: 'LK - Sri Lanka'
    }, {
      countryCode: 'SD',
      name: 'SD - Sudan'
    }, {
      countryCode: 'SR',
      name: 'SR - Suriname'
    }, {
      countryCode: 'SJ',
      name: 'SJ - Svalbard And Jan Mayen'
    }, {
      countryCode: 'SZ',
      name: 'SZ - Swaziland'
    }, {
      countryCode: 'SE',
      name: 'SE - Sweden'
    }, {
      countryCode: 'CH',
      name: 'CH - Switzerland'
    }, {
      countryCode: 'SY',
      name: 'SY - Syrian Arab Republic'
    }, {
      countryCode: 'TW',
      name: 'TW - Taiwan'
    }, {
      countryCode: 'TJ',
      name: 'TJ - Tajikistan'
    }, {
      countryCode: 'TZ',
      name: 'TZ - Tanzania'
    }, {
      countryCode: 'TH',
      name: 'TH - Thailand'
    }, {
      countryCode: 'TL',
      name: 'TL - Timor-Leste'
    }, {
      countryCode: 'TG',
      name: 'TG - Togo'
    }, {
      countryCode: 'TK',
      name: 'TK - Tokelau'
    }, {
      countryCode: 'TO',
      name: 'TO - Tonga'
    }, {
      countryCode: 'TT',
      name: 'TT - Trinidad And Tobago'
    }, {
      countryCode: 'TN',
      name: 'TN - Tunisia'
    }, {
      countryCode: 'TR',
      name: 'TR - Turkey'
    }, {
      countryCode: 'TM',
      name: 'TM - Turkmenistan'
    }, {
      countryCode: 'TC',
      name: 'TC - Turks And Caicos Islands'
    }, {
      countryCode: 'TV',
      name: 'TV - Tuvalu'
    }, {
      countryCode: 'UG',
      name: 'UG - Uganda'
    }, {
      countryCode: 'UA',
      name: 'UA - Ukraine'
    }, {
      countryCode: 'AE',
      name: 'AE - United Arab Emirates'
    }, {
      countryCode: 'GB',
      name: 'GB - United Kingdom'
    }, {
      countryCode: 'US',
      name: 'US - United States'
    }, {
      countryCode: 'UM',
      name: 'UM - United States Outlying Islands'
    }, {
      countryCode: 'UY',
      name: 'UY - Uruguay'
    }, {
      countryCode: 'UZ',
      name: 'UZ - Uzbekistan'
    }, {
      countryCode: 'VU',
      name: 'VU - Vanuatu'
    }, {
      countryCode: 'VE',
      name: 'VE - Venezuela'
    }, {
      countryCode: 'VN',
      name: 'VN - Vietnam'
    }, {
      countryCode: 'VG',
      name: 'VG - Virgin Islands, British'
    }, {
      countryCode: 'VI',
      name: 'VI - Virgin Islands, U.S.'
    }, {
      countryCode: 'WF',
      name: 'WF - Wallis And Futuna'
    }, {
      countryCode: 'EH',
      name: 'EH - Western Sahara'
    }, {
      countryCode: 'YE',
      name: 'YE - Yemen'
    }, {
      countryCode: 'ZM',
      name: 'ZM - Zambia'
    }, {
      countryCode: 'ZW',
      name: 'ZW - Zimbabwe'
    }]
  }));

  _exports.default = _default;
});