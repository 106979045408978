define("blackcat/validators/server-side-validation", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    ajax: Ember.inject.service('v1-api'),

    validate(value, options) {
      if (Ember.isEmpty(value)) {
        return true;
      }

      return this.ajax.request(this._buildURL(options), this._buildOptions(options)).then(() => true).catch(() => this.createErrorMessage('invalid', value, options));
    },

    _buildURL(options) {
      return `/${options.endpoint}`;
    },

    _buildOptions(options) {
      return {
        method: 'GET',
        data: options.data
      };
    }

  });

  _exports.default = _default;
});