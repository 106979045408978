define("blackcat/industries/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    title: 'Industries',
    actions: {
      goToIndustryNew() {
        this.transitionTo('industries.new');
      },

      goToIndustryShow(industry) {
        this.transitionTo('industries.show', industry);
      },

      removeIndustry(industry) {
        industry.destroyRecord();
      }

    }
  });

  _exports.default = _default;
});