define("blackcat/models/business-signup", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Business name'
    }),
    subdomain: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Subdomain',
      message: 'Subdomain must be present'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /(^[A-Za-z0-9-]{3,63}$)/,
      message: 'Web address can only contain letters, numbers and/or dashes, and be between 3 and 63 characters.',
      description: 'Subdomain'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /(^[A-Za-z0-9].*[A-Za-z0-9]$)/,
      message: 'Web address must begin and end in a letter or number.',
      description: 'Subdomain'
    }), (0, _emberCpValidations.validator)('unique-resource', {
      endpoint: 'businesses/check_subdomain',
      message: 'Web address is not available'
    })],
    countryCode: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      dependentKeys: ['phoneNumber'],
      disabled: Ember.computed('model.phoneNumber', function () {
        return !Ember.isPresent(Ember.get(this, 'model.phoneNumber'));
      }),
      description: 'Country code'
    })],
    phoneNumber: [(0, _emberCpValidations.validator)('format', {
      regex: /^[^a-zA-z]*$/,
      allowBlank: true,
      message: 'Phone number must be valid',
      description: 'Phone number'
    })],
    clusterId: [(0, _emberCpValidations.validator)('unique-resource', {
      endpoint: 'businesses/check_cluster_id',
      message: 'An account for this hotel already exists'
    })],
    adminName: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Admin name'
    })],
    email: [(0, _emberCpValidations.validator)('format', {
      type: 'email'
    }), (0, _emberCpValidations.validator)('unique-resource', {
      endpoint: 'users/check_email'
    })],
    username: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Username'
    }), (0, _emberCpValidations.validator)('unique-resource', {
      endpoint: 'users/check_username'
    })]
  });

  var _default = _model.default.extend(Validations, {
    // Business Properties
    name: (0, _model.attr)('string'),
    subdomain: (0, _model.attr)('string'),
    phoneNumber: (0, _model.attr)('string'),
    clusterId: (0, _model.attr)('string'),
    clusterAddress: (0, _model.attr)('string'),
    roomCount: (0, _model.attr)('string'),
    countryCode: (0, _model.attr)('string', {
      defaultValue: 'US'
    }),
    businessId: (0, _model.attr)('string'),
    skipOnboardingEmail: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    usernameExists: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    subscription: (0, _model.attr)('string'),
    channels: (0, _model.attr)('json'),
    salesforceBusinessType: (0, _model.attr)('string'),
    trialEndDate: (0, _model.attr)('json'),
    // Admin properties
    adminName: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    username: (0, _model.attr)('string'),
    // Onsite survey
    surveyId: (0, _model.attr)('string')
  });

  _exports.default = _default;
});