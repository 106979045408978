define("blackcat/businesses/business/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qsEq/ylV",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"business-edit\",null,[[\"business\",\"salesforceData\",\"transitionToBusiness\"],[[24,[\"model\"]],[24,[\"salesforceData\"]],[28,\"route-action\",[\"transitionToBusiness\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "blackcat/businesses/business/edit/template.hbs"
    }
  });

  _exports.default = _default;
});