define("blackcat/components/business-user-list-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    successFeedbackDelay: 2000,
    classNames: ['businessUserListItem'],
    ajax: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.setProperties({
        showConfirmation: false,
        reason: null,
        errorMessage: null
      });
    },

    // Computed Properties
    name: Ember.computed.oneWay('model.name'),
    email: Ember.computed.oneWay('model.email'),
    type: Ember.computed.oneWay('model.type'),
    emailHref: Ember.computed('email', function () {
      return `mailto:${this.email}`;
    }),

    _impersonateAction(reason) {
      if (Ember.isBlank(reason)) {
        Ember.set(this, 'errorMessage', 'You must provide a reason');
      } else {
        const business = Ember.get(this, 'business');
        Ember.get(this, 'ajax').post(`/users/${Ember.get(this, 'model.id')}/impersonate`, {
          data: {
            reason,
            business: business.get('id')
          }
        }).then(response => {
          const {
            url
          } = response.data.attributes;
          window.open(url);
          this.toggleProperty('showConfirmation');
          Ember.set(this, 'reason', null);
        });
      }
    },

    _setSendingStatus(type, value) {
      if (type === 'reset_password') {
        Ember.set(this, 'sendingPasswordEmail', value);
      }

      if (type === 'authenticate_email') {
        Ember.set(this, 'sendingAuthEmail', value);
      }
    },

    _sendEmail(type) {
      this._setSendingStatus(type, true);

      return Ember.get(this, 'ajax').post(`/users/${Ember.get(this, 'model.id')}/send_email`, {
        data: {
          email_type: type
        }
      }).finally(() => {
        this._setSendingStatus(type, false);
      });
    },

    actions: {
      closeImpersonateDialog(action, reason) {
        if (Ember.isEqual(action, 'ok')) {
          this._impersonateAction(reason);
        } else {
          this.toggleProperty('showConfirmation');
        }
      },

      toggleShowConfirmation() {
        this.toggleProperty('showConfirmation');
      },

      reactivate() {
        if (Ember.get(this, 'model.isDeleted')) {
          Ember.set(this, 'model.isDeleted', false);
          Ember.get(this, 'model').save();
        }
      },

      /* eslint-disable camelcase */
      sendPasswordResetEmail() {
        Ember.set(this, 'passwordResetEmailSent', true);

        this._sendEmail('reset_password').then(() => {
          Ember.run.later(this, function () {
            Ember.set(this, 'passwordResetEmailSent', false);
          }, Ember.get(this, 'successFeedbackDelay'));
        });
      },

      sendAuthenticationEmail() {
        Ember.set(this, 'sendAuthenticationEmailSent', true);

        this._sendEmail('authenticate_email').then(() => {
          Ember.run.later(this, function () {
            Ember.set(this, 'sendAuthenticationEmailSent', false);
          }, Ember.get(this, 'successFeedbackDelay'));
        });
      },

      /* eslint-enable camelcase */
      impersonate() {
        const reason = Ember.get(this, 'reason');

        this._impersonateAction(reason);
      }

    }
  });

  _exports.default = _default;
});