define("blackcat/models/industry", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Name'
    })
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _model.attr)('string'),
    customer: (0, _model.attr)('string', {
      defaultValue: 'customer'
    }),
    customers: (0, _model.attr)('string', {
      defaultValue: 'customers'
    }),
    business: (0, _model.attr)('string', {
      defaultValue: 'business'
    }),
    businesses: (0, _model.attr)('string', {
      defaultValue: 'businesses'
    }),
    teams: (0, _model.attr)('string', {
      defaultValue: 'Customer Service, Sales, Accounting, Management'
    }),
    smsEnrollmentMessage: (0, _model.attr)('string', {
      defaultValue: 'Thanks for getting in touch! You can reply to this message at any time to reach our team. How can we help you today?'
    }),
    smsEnrollmentPageText: (0, _model.attr)('string', {
      defaultValue: 'Now you can reach our team by text message. Enter your mobile number and name to get the conversation started.'
    }),
    savedReplyTemplates: (0, _model.attr)('json', {
      defaultValue: [{
        name: 'New Customer',
        template: "Hi, {{customer.first_name | default: 'there'}}. Thank you for choosing {{business.name}}. You can reply to this message at anytime to reach our team."
      }, {
        name: 'Feedback',
        template: 'How was your recent experience with {{business.name}}? Do you have any feedback for how we can improve our service?'
      }]
    }),
    translations: (0, _model.attr)('json', {
      defaultValue: {
        es: {
          business: 'empresa',
          businesses: 'empresas',
          customer: 'cliente',
          customers: 'clientes',
          teams: ['Servicio al Cliente', 'Ventas', 'Contabilidad', 'Administración'],
          sms_enrollment_message: '¡Gracias por ponerse en contacto! Puede responder a este mensaje en cualquier momento para dirigirse a nuestro equipo. ¿Cómo podemos ayudarle hoy?',
          sms_enrollment_page_text: 'Ahora se puede poner en contacto con nuestro equipo a través de mensajes de texto. Introduzca su número de teléfono y su nombre para iniciar la conversación.'
        },
        fr: {
          business: 'entreprise',
          businesses: 'entreprises',
          customer: 'client',
          customers: 'clients',
          teams: ['Service Clients', 'Ventes', 'Comptabilité', 'La Gestion'],
          sms_enrollment_message: "Merci de nous avoir contactés! Vous pouvez répondre à ce message en tout temps pour joindre notre équipe. Comment pouvons-nous vousaider aujourd'hui?",
          sms_enrollment_page_text: 'Vous pouvez maintenant joindre notre équipe par messagerie texte. Entrez votre numéro de téléphone cellulaire et votre nom pour entamer la conversation.'
        },
        de: {
          business: 'Geschäft',
          businesses: 'Unternehmen',
          customer: 'Kunde',
          customers: 'Kundschaft',
          teams: ['Klantenservice', 'Verkoop', 'Accounting', 'Beheer'],
          sms_enrollment_message: 'Vielen Dank, dass Sie uns kontaktiert haben. Sie können auf diese Nachricht jederzeit antworten, um unser Team zu erreichen. Wie können wir Ihnen heute helfen?',
          sms_enrollment_page_text: 'Sie können unser Team jetzt per SMS erreichen. Geben Sie Ihre Mobilfunknummer und Ihren Namen ein, um eine Unterhaltung zu beginnen.'
        },
        pt: {
          business: 'empresa',
          businesses: 'as empresas',
          customer: 'cliente',
          customers: 'clientes',
          teams: ['Serviço ao cliente', 'Vendas', 'Contabilidade', 'Gestão'],
          sms_enrollment_message: 'Obrigado por entrar em contacto! Pode responder a esta mensagem a qualquer hora para contactar a nossa equipa. Como podemos ajudá-lo hoje?',
          sms_enrollment_page_text: 'Agora pode contactar a nossa equipa por mensagem de texto. Introduza o seu número de telemóvel e o seu nome para iniciar a conversa.'
        },
        ru: {
          business: 'бизнес',
          businesses: 'предприятия',
          customer: 'клиент',
          customers: 'клиенты',
          teams: ['Обслуживание клиентов', 'Продажи', 'Yчет', 'Yправление'],
          sms_enrollment_message: 'Благодарим Вас за обращение! Вы можете ответить на это сообщение и связаться с нашей службой в любое время. Чем мы можем помочь Вам сегодня?',
          sms_enrollment_page_text: 'Теперь Вы можете связаться с нашей командой с помощью текстовых сообщений. Введите Ваш номер мобильного и имя, чтобы начать переписку.'
        },
        it: {
          business: 'azienda',
          businesses: 'aziende',
          customer: 'cliente',
          customers: 'clienti',
          teams: ['Assistenza clienti', 'Vendite', 'Contabilità', 'Gestione'],
          sms_enrollment_message: 'Grazie per averci contattato! Puoi rispondere a questo messaggio in qualunque momento per raggiungere il nostro team. Come ti possiamo aiutare oggi?',
          sms_enrollment_page_text: 'Adesso puoi raggiungere il nostro team tramite messaggi di testo. Inserisci il tuo numero di cellulare e il tuo nome per iniziare la conversazione.'
        }
      }
    }),
    businessSchemaTemplate: (0, _model.attr)('json'),
    teamsList: Ember.computed('teams', function () {
      const teams = this.teams;
      return Ember.isPresent(teams) ? teams.split(', ') : Ember.A([]);
    })
  });

  _exports.default = _default;
});