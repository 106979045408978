define("blackcat/mirage/config", ["exports", "blackcat/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  _exports.testConfig = testConfig;

  function subscriptionMockedData() {
    return {
      data: [{
        id: 'fde412b',
        type: 'subscriptions',
        attributes: {
          name: 'live messaging',
          title: 'Live messaging',
          default_channels_enabled: ['email', 'webchat', 'facebook'],
          modifiable_channels: ['whatsapp', 'sms', 'wechat']
        }
      }, {
        id: 'c2d4fe2',
        type: 'subscriptions',
        attributes: {
          name: 'live survey',
          title: 'Live survey',
          default_channels_enabled: ['email'],
          modifiable_channels: []
        }
      }, {
        id: '24ebf66',
        type: 'subscriptions',
        attributes: {
          name: 'live experience',
          title: 'Live experience',
          default_channels_enabled: ['email', 'webchat', 'facebook'],
          modifiable_channels: ['whatsapp', 'sms', 'wechat']
        }
      }]
    };
  }

  function businessMockedData(request, balance, db) {
    return {
      data: {
        id: request.params.id,
        type: 'businesses',
        attributes: db.businesses.find(request.params.id),
        relationships: {
          balance: {
            data: {
              id: balance.id,
              type: 'balances'
            }
          }
        }
      },
      included: [{
        id: balance.id,
        type: 'balances',
        attributes: balance
      }]
    };
  }

  function _default() {
    this.urlPrefix = _environment.default.APP.API_SERVER;
    this.namespace = 'internal';
    this.passthrough('/industries');
    this.passthrough('/industries/:id');
  }

  function testConfig() {
    this.urlPrefix = _environment.default.APP.API_SERVER;
    this.namespace = 'internal';
    this.get('/industries');
    this.get('/subscriptions', () => subscriptionMockedData());
    this.post('/industries');
    this.get('/industries/:id');
    this.put('/industries/:id', (db, request) => {
      const [id, requestBody] = [request.params.id, request.requestBody];
      const attrs = JSON.parse(requestBody).industry;
      const record = db.industries.update(id, attrs);
      return {
        industry: record
      };
    });
    this.del('/industries/:id');
    this.get('/businesses/:id', (db, request) => {
      const business = db.businesses.find(request.params.id);
      const {
        balance
      } = business;
      return businessMockedData(request, balance, db);
    });
  }
});