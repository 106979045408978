define("blackcat/models/user", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    isAdmin: (0, _model.attr)('boolean'),
    type: (0, _model.attr)('string'),
    isDeleted: (0, _model.attr)('boolean'),
    users: (0, _model.hasMany)('business')
  });

  _exports.default = _default;
});