define("blackcat/models/subscription", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    default_channels_enabled: (0, _model.attr)('json'),
    modifiable_channels: (0, _model.attr)('json'),
    business: (0, _model.belongsTo)('business'),

    upgrade() {
      return this.changeSubscription('upgrade');
    },

    downgrade() {
      return this.changeSubscription('downgrade');
    },

    changeSubscription(action) {
      const businessId = Ember.get(this, 'business.id');
      const store = this.store;
      const {
        modelName
      } = this.constructor;
      const adapter = store.adapterFor(modelName);
      const call = Ember.get(adapter, action).bind(adapter);
      return call(businessId).then(response => store.pushPayload(response));
    }

  });

  _exports.default = _default;
});