define("blackcat/settings/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    title: 'Settings',
    session: Ember.inject.service(),

    model() {
      return this.get('session.currentUser');
    },

    actions: {
      willTransition() {
        this.currentModel.rollbackAttributes();
      }

    }
  });

  _exports.default = _default;
});