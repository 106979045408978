define("blackcat/components/submenu-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rlQnEScx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"paper-item\",null,[[\"onClick\",\"class\"],[[24,[\"onClick\"]],[28,\"concat\",[\"submenu-item\",[28,\"if\",[[24,[\"active\"]],\" active\"],null]],null]]],{\"statements\":[[14,1]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "blackcat/components/submenu-item/template.hbs"
    }
  });

  _exports.default = _default;
});