define("blackcat/businesses/business/controller", ["exports", "moment", "blackcat/mixins/business-presenter-mixin", "blackcat/utils/salesforceDataSerializer"], function (_exports, _moment, _businessPresenterMixin, _salesforceDataSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable camelcase */
  var _default = Ember.Controller.extend(_businessPresenterMixin.default, {
    salesforceData: Ember.computed('model.salesforceData', function () {
      if (Ember.get(this, 'model.salesforceData')) {
        const finalData = (0, _salesforceDataSerializer.default)(Ember.get(this, 'model.salesforceData'));
        return finalData;
      }

      return null;
    }),
    canSaveBusiness: Ember.computed('model.hasDirtyAttributes', function () {
      return Ember.get(this, 'model.hasDirtyAttributes');
    }),
    trialEndDateFormatted: Ember.computed('model.trialEndDate', function () {
      if (Ember.get(this, 'model.trialEndDate')) {
        const date = Ember.get(this, 'model.trialEndDate').format('D.M.YYYY');
        return date;
      }

      return Ember.get(this, 'model.trialEndDate');
    }),
    businessStatus: Ember.computed('model.{subscriptionActive,trialEndDate}', 'subscriptionStatus', function () {
      if ((0, _moment.default)(Ember.get(this, 'model.subscriptionActive')).isAfter((0, _moment.default)())) {
        return 'Trial';
      }

      return this.subscriptionStatus;
    })
  });

  _exports.default = _default;
});