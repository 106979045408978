define("blackcat/industries/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken(model) {
      return model.get('name');
    },

    model(params) {
      return this.store.find('industry', params.id);
    }

  });

  _exports.default = _default;
});