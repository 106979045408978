define("blackcat/components/login-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hj5IyxUb",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[7,\"center\",true],[8],[7,\"h3\",true],[8],[0,\"TrustYou Messaging Admin\"],[9],[9],[0,\"\\n\"],[4,\"paper-form\",null,[[\"onSubmit\"],[[28,\"action\",[[23,0,[]],\"logIn\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"layout-column\"],[8],[0,\"\\n    \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"input\"]],\"expected `form.input` to be a contextual component but found a string. Did you mean `(component form.input)`? ('blackcat/components/login-form/template.hbs' @ L4:C6) \"],null]],[[\"label\",\"type\",\"value\",\"onChange\",\"required\"],[\"E-mail\",\"email\",[24,[\"email\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"email\"]]],null]],null],true]]],false],[0,\"\\n    \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"input\"]],\"expected `form.input` to be a contextual component but found a string. Did you mean `(component form.input)`? ('blackcat/components/login-form/template.hbs' @ L5:C6) \"],null]],[[\"label\",\"type\",\"value\",\"onChange\",\"required\"],[\"Password\",\"password\",[24,[\"password\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"password\"]]],null]],null],true]]],false],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[4,\"paper-button\",null,[[\"raised\",\"primary\",\"disabled\",\"onClick\"],[true,true,[23,1,[\"isInvalid\"]],[28,\"action\",[[23,0,[]],[23,1,[\"onSubmit\"]]],null]]],{\"statements\":[[0,\"Sign In\"]],\"parameters\":[]},null],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"errorMessage\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[10,\"class\",\"error\"],[8],[1,[22,\"errorMessage\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "blackcat/components/login-form/template.hbs"
    }
  });

  _exports.default = _default;
});