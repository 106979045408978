define("blackcat/components/loading-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jKpZ+7Pn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"layout-row layout-align-center-center\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"layout\",\"row\"],[10,\"layout-align\",\"center center\"],[8],[0,\"\\n    \"],[1,[22,\"paper-progress-circular\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"defaultText\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"flex\",\"100\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"layout\",\"row\"],[10,\"layout-align\",\"center center\"],[8],[0,\"\\n      \"],[7,\"h2\",true],[8],[1,[22,\"defaultText\"],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "blackcat/components/loading-content/template.hbs"
    }
  });

  _exports.default = _default;
});