define("blackcat/components/business-settings/component", ["exports", "blackcat/mixins/business-form-mixin", "moment"], function (_exports, _businessFormMixin, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_businessFormMixin.default, {
    countryService: Ember.inject.service('country'),
    trialEndDateFormatted: Ember.computed('business.trialEndDate', function () {
      const date = Ember.get(this, 'business.trialEndDate').format('D.M.YYYY');
      return date;
    }),
    statusClassName: Ember.computed('business.trial', function () {
      if (Ember.get(this, 'business.trial')) {
        return 'business-status-radio-group disabled';
      }

      return 'business-status-radio-group';
    }),

    init() {
      this._super(...arguments);

      if (Ember.isPresent(Ember.get(this, 'business.trialEndDate'))) {
        Ember.set(this, 'selectedTrialEndDate', Ember.get(this, 'business.trialEndDate').toDate());
      }

      if (Ember.isPresent(this.businessType)) {
        Ember.set(this, 'business.businessType', this.businessType);
      }
    },

    actions: {
      dateChanged([newDate]) {
        // we don't want to set the trialEndDate on the initial
        // firing of this event by flatpickr because it'll make
        // the model dirty
        const currentDate = Ember.get(this, 'business.trialEndDate');
        const updatedDate = (0, _moment.default)(newDate);

        if (Ember.isPresent(newDate) && !updatedDate.isSame(currentDate, 'day')) {
          Ember.set(this, 'selectedTrialEndDate', updatedDate.toDate());
          this.business.set('trialEndDate', updatedDate);
        }
      }

    }
  });

  _exports.default = _default;
});