define("blackcat/components/business-list-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3IeAqJMg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"paper-item\",null,[[\"class\",\"onClick\"],[\"md-3-line\",[28,\"transition-to\",[\"businesses.business\",[24,[\"model\",\"id\"]]],null]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"md-list-item-text\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[8],[7,\"b\",true],[8],[1,[24,[\"model\",\"name\"]],false],[9],[9],[0,\"\\n    \"],[7,\"h4\",true],[8],[0,\"Status: \"],[1,[22,\"subscriptionStatus\"],false],[9],[0,\"\\n    \"],[7,\"h4\",true],[8],[0,\"Trial End Date: \"],[1,[28,\"if\",[[24,[\"model\",\"trialEndDate\"]],[28,\"moment-format\",[[24,[\"model\",\"trialEndDate\"]],\"YYYY-MM-DD\"],null],\"N/A\"],null],false],[9],[0,\"\\n    \"],[7,\"h4\",true],[8],[0,\"TY Cluster: \"],[1,[22,\"clusterDisplay\"],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"paper-button\",null,[[\"onClick\",\"iconButton\",\"class\",\"primary\"],[[28,\"transition-to\",[\"businesses.business\",[24,[\"model\",\"id\"]]],null],true,\"md-secondary\",true]],{\"statements\":[[0,\"    \"],[1,[28,\"paper-icon\",null,[[\"icon\"],[\"edit\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "blackcat/components/business-list-item/template.hbs"
    }
  });

  _exports.default = _default;
});