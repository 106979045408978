define("blackcat/utils/array-to-sentence", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = arrayToSentence;

  function arrayToSentence(array) {
    let sentence;

    switch (array.length) {
      case 1:
        sentence = array[0];
        break;

      case 2:
        sentence = array.join(' and ');
        break;

      default:
        sentence = `${array.slice(0, array.length - 1).join(', ')}, and ${array.slice(-1)}`;
    }

    return sentence;
  }
});