define("blackcat/components/business-search/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    searchBusinesses: (0, _emberConcurrency.task)(function* (filterText) {
      yield (0, _emberConcurrency.timeout)(300);

      if (Ember.isPresent(filterText) && filterText.length >= 3) {
        return this.store.query('business', {
          q: filterText,
          include: 'business_settings,balance,business_channels,subscription',
          limit: 10
        });
      }

      return [];
    }),
    actions: {
      selectionChanged(business) {
        if (Ember.isPresent(business)) {
          this.sendAction('businessChosen', business.get('id'));
        }
      }

    }
  });

  _exports.default = _default;
});