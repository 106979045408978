define("blackcat/utils/parseEligibleChannels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parseEligibleChannels;

  function parseEligibleChannels(channelsArray) {
    if (channelsArray) {
      const parsed = channelsArray.map(channel => {
        if (channel === 'live-chat') {
          return 'webchat';
        }

        if (channel === 'fb-messenger') {
          return 'facebook';
        }

        return channel;
      }); // eslint-disable-next-line no-undef

      return Array.from(new Set(parsed));
    }

    return [];
  }
});