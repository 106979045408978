define("blackcat/services/v1-api", ["exports", "blackcat/services/ajax", "blackcat/config/environment", "blackcat/utils/parseEligibleChannels", "blackcat/utils/parseFloatToString"], function (_exports, _ajax, _environment, _parseEligibleChannels, _parseFloatToString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable camelcase */
  const HOST = _environment.default.APP.API_SERVER;

  var _default = _ajax.default.extend({
    host: HOST,

    additionalBusinessInfo(clusterId) {
      return this.request(`/internal/business-additional-infos/${clusterId}`).then(this._successAdditionalInfoCallback.bind(this)).catch(e => e);
    },

    usersFromCluster(clusterId) {
      return this.request(`/internal/clusters/${clusterId}/users`, {}).then(result => result.map(item => ({
        name: item.name,
        email: item.email,
        username: item.username
      })));
    },

    _successAdditionalInfoCallback(response) {
      if (response.errors) {
        return {
          salesforceData: {
            errors: response.errors
          }
        };
      }

      const {
        data,
        included
      } = response;
      const {
        attributes: {
          onsite_surveys: onsiteSurveys
        }
      } = data;
      const salesforceInfo = included.find(resource => resource.type === 'salesforce_accounts');
      const {
        attributes
      } = salesforceInfo;
      const {
        wallet_threshold,
        balance_value,
        billing_country,
        consumption_model_name,
        currency,
        invoicing_frequency,
        name,
        number_of_rooms,
        status,
        ready_for_paid_channels,
        wallet_top_up_amount,
        eligible_channels,
        next_invoicing_date
      } = attributes;
      const parsedChannels = (0, _parseEligibleChannels.default)(eligible_channels);
      const salesforceData = {
        type: 'success',
        balance_value: (0, _parseFloatToString.default)(balance_value),
        wallet_threshold: (0, _parseFloatToString.default)(wallet_threshold),
        wallet_top_up_amount: (0, _parseFloatToString.default)(wallet_top_up_amount),
        billing_country,
        consumption_model_name,
        currency,
        invoicing_frequency,
        name,
        number_of_rooms,
        status,
        ready_for_paid_channels,
        eligible_channels: parsedChannels,
        next_invoicing_date
      };
      return {
        salesforceData,
        onsiteSurveys
      };
    }

  });

  _exports.default = _default;
});