define("blackcat/components/business-list-item/component", ["exports", "blackcat/mixins/business-presenter-mixin"], function (_exports, _businessPresenterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_businessPresenterMixin.default);

  _exports.default = _default;
});