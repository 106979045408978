define("blackcat/industries/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+I65GLPI",
    "block": "{\"symbols\":[\"industry\"],\"statements\":[[4,\"paper-button\",null,[[\"noink\",\"primary\",\"action\",\"class\",\"bubbles\"],[true,true,\"goToIndustryNew\",\"goToIndustryNew\",false]],{\"statements\":[[0,\"New Industry\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[4,\"paper-list\",null,null,{\"statements\":[[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[4,\"unless\",[[23,1,[\"isNew\"]]],null,{\"statements\":[[4,\"paper-item\",null,[[\"class\",\"onClick\",\"bubbles\"],[\"md-3-line goToIndustryShow\",[28,\"route-action\",[\"goToIndustryShow\",[23,1,[]]],null],false]],{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"md-list-item-text\"],[8],[0,\"\\n          \"],[7,\"h3\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n          \"],[7,\"h4\",true],[8],[0,\"Customer: \"],[1,[23,1,[\"customer\"]],false],[9],[0,\"\\n          \"],[7,\"h4\",true],[8],[0,\"Business: \"],[1,[23,1,[\"business\"]],false],[9],[0,\"\\n          \"],[7,\"h4\",true],[8],[0,\"Teams: \"],[1,[23,1,[\"teams\"]],false],[9],[0,\"\\n\"],[4,\"paper-button\",null,[[\"onClick\",\"iconButton\",\"bubbles\",\"class\",\"warn\"],[[28,\"route-action\",[\"removeIndustry\",[23,1,[]]],null],true,false,\"md-secondary removeIndustry\",true]],{\"statements\":[[0,\"            \"],[1,[28,\"paper-icon\",null,[[\"icon\"],[\"delete\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[1,[22,\"paper-divider\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "blackcat/industries/index/template.hbs"
    }
  });

  _exports.default = _default;
});