define("blackcat/users/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ability: Ember.inject.service('can'),
    title: 'Users',

    beforeModel() {
      this._super(...arguments);

      if (this.ability.cannot('view users')) {
        this.transitionTo('application');
      }
    },

    model() {
      return this.store.findAll('internalUser');
    },

    afterModel(model) {
      model.set('newUser', this.store.createRecord('internalUser'));
    },

    actions: {
      willTransition() {
        const newUser = this.currentModel.newUser;

        if (newUser.get('isNew')) {
          newUser.unloadRecord();
        }
      },

      setupNewUser() {
        this.currentModel.set('newUser', this.store.createRecord('internalUser'));
      }

    }
  });

  _exports.default = _default;
});