define("blackcat/tests/mirage/mirage.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | mirage');
  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/balance.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/balance.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/business.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/business.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/industry.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/industry.js should pass ESLint\n\n');
  });
});