define("blackcat/services/v1-ty", ["exports", "ember-ajax/services/ajax", "blackcat/config/environment", "blackcat/models/cluster"], function (_exports, _ajax, _environment, _cluster) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    features: Ember.inject.service(),
    host: _environment.default.APP.TY_SEARCH_URL,

    clusterSearch(term) {
      if (this.get('features.legacyClusterSearch')) {
        return this.request('/hotels/search', {
          data: {
            /* eslint-disable camelcase */
            page_size: 7,

            /* eslint-enable camelcase */
            page: 0,
            prefix: term
          }
        }).then(result => result.response.cluster_list.map(item => new _cluster.default({
          name: item.name,
          clusterId: item.cluster_id,
          address: item.city
        })));
      }

      return this.request('/data/hotel/v1/clusters/search', {
        data: {
          limit: 7,
          fields: 'cluster_id,name,address',
          offset: 0,
          text: term
        }
      }).then(result => result.response.data.map(item => new _cluster.default({
        name: item.name,
        clusterId: item.cluster_id,
        address: `${item.address.city}, ${item.address.country_code}`
      })));
    }

  });

  _exports.default = _default;
});