define("blackcat/components/salesforce-card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    consumptionModelName: Ember.computed.alias('salesforce.consumption_model_name'),
    defaultNotAvailableMessage: 'information not available',
    errorsDetails: Ember.computed('salesforce.errors', function () {
      const error = Ember.get(this, 'salesforce.errors').get('firstObject');
      const allDetails = error.detail.split('.');
      return allDetails;
    })
  });

  _exports.default = _default;
});