define("blackcat/models/integration", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SUPPORTED_INTEGRATIONS = ['frontdesk-anywhere', 'stay-n-touch'];

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    emailAddress: (0, _model.attr)('string'),
    lastImportStatus: (0, _model.attr)('string'),
    lastImportAt: (0, _model.attr)('date'),
    lastImportRecordCount: (0, _model.attr)('number'),
    lastImportFailureCount: (0, _model.attr)('number'),
    lastImportSuccessCount: (0, _model.attr)('number'),
    sampleFile: (0, _model.attr)('json'),
    meta: (0, _model.attr)('json'),
    mapping: (0, _model.attr)('json'),
    business: (0, _model.belongsTo)('business'),
    imports: (0, _model.hasMany)('import', {
      async: false
    }),
    integrationType: Ember.computed.alias('meta.type'),
    isThirdParty: Ember.computed('integrationType', function () {
      const type = this.integrationType;
      return Ember.isPresent(type) && SUPPORTED_INTEGRATIONS.includes(type);
    })
  });

  _exports.default = _default;
});