define("blackcat/models/internal-user", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'name'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^[a-zA-Z\s]+$/,
      message: 'Name must be a string'
    })],
    email: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'email'
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      message: 'Email must be a valid email address'
    })],
    password: (0, _emberCpValidations.validator)('length', {
      min: 8,
      allowBlank: true,
      description: 'password'
    })
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _model.attr)('string'),
    password: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    isAdmin: (0, _model.attr)('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});