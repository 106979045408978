define("blackcat/components/business-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M2IG2dj2",
    "block": "{\"symbols\":[\"business\",\"autocomplete\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"layout-row layout-align-center-center\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex\"],[8],[0,\"\\n\"],[4,\"paper-autocomplete\",null,[[\"placeholder\",\"search\",\"searchText\",\"onSearchTextChange\",\"searchMessage\",\"onSelectionChange\"],[\"Select a Business...\",[28,\"perform\",[[24,[\"searchBusinesses\"]]],null],[28,\"readonly\",[[24,[\"filterText\"]]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"filterText\"]]],null]],null],false,[28,\"action\",[[23,0,[]],\"selectionChanged\"],null]]],{\"statements\":[[0,\"      \"],[1,[28,\"paper-autocomplete-highlight\",null,[[\"searchText\",\"label\",\"flags\"],[[23,2,[\"searchText\"]],[28,\"concat\",[[23,1,[\"name\"]],\" (\",[23,1,[\"subdomain\"]],\")\"],null],\"i\"]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},{\"statements\":[[0,\"      \"],[7,\"ul\",true],[10,\"class\",\"ember-power-select-options md-autocomplete-suggestions\"],[10,\"role\",\"listbox\"],[8],[0,\"\\n        \"],[7,\"li\",true],[10,\"class\",\"ember-power-select-option ember-power-select-option--no-matches-message\"],[10,\"role\",\"option\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"gte\",[[24,[\"filterText\",\"length\"]],3],null]],null,{\"statements\":[[0,\"            Unable to locate a business by that name.\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            Please keep typing to search (minimum 3 characters required).\\n\"]],\"parameters\":[]}],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "blackcat/components/business-search/template.hbs"
    }
  });

  _exports.default = _default;
});