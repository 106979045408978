define("blackcat/components/business-new/component", ["exports", "blackcat/mixins/cluster-autocomplete-mixin", "blackcat/mixins/admin-autocomplete-mixin", "blackcat/mixins/business-form-mixin", "blackcat/config/environment", "blackcat/utils/array-to-sentence", "blackcat/constants/business"], function (_exports, _clusterAutocompleteMixin, _adminAutocompleteMixin, _businessFormMixin, _environment, _arrayToSentence, _business) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_clusterAutocompleteMixin.default, _adminAutocompleteMixin.default, _businessFormMixin.default, {
    api: Ember.inject.service('v1-api'),
    countryService: Ember.inject.service('country'),
    adminExistsinTy: false,
    showAdminForm: Ember.computed.not('adminExistsinTy'),
    isErrorMessageArray: Ember.computed('errorMessage', function () {
      return Array.isArray(this.errorMessage);
    }),
    channelsToSave: Object.freeze([]),
    showCards: Ember.computed('salesforce.type', function () {
      return Ember.get(this, 'salesforce.type') === 'success';
    }),
    classNames: ['businessNew'],
    showSaveUsingExistingUser: false,
    onsiteSurveysCount: Ember.computed('onsiteSurveys', function () {
      const surveys = this.onsiteSurveys;

      if (surveys) {
        return surveys.length;
      }

      return null;
    }),
    surveySubtitle: Ember.computed('onsiteSurveysCount', function () {
      const onsiteSurveysCount = this.onsiteSurveysCount;

      if (onsiteSurveysCount === 0) {
        return 'A On-Site Survey will be configured to collect on-site feedback';
      }

      if (onsiteSurveysCount === 1) {
        return 'The existing On-Site Survey will be configured to collect on-site feedback';
      }

      if (onsiteSurveysCount > 1) {
        return 'Please, select the On-Site Survey you want to use to collect on-site feedback';
      }

      return null;
    }),

    init() {
      this._super(...arguments);

      Ember.set(this, 'business.salesforceBusinessType', 'Customer');
    },

    didReceiveAttrs() {
      this._super(...arguments);

      if (Ember.isPresent(this.get('business.countryCode'))) {
        const country = this.get('countryService.selectOptions').findBy('countryCode', this.get('business.countryCode'));
        Ember.set(this, 'selectedCountry', country);
      } else {
        Ember.set(this, 'selectedCountry', null);
      }
    },

    /**
     * @public
     * Public properties accepted by business-new
     * @param {Business} business - The new business
     */
    rootDomain: _environment.default.APP.APP_DOMAIN,

    _updateUsername(username) {
      Ember.setProperties(this, {
        'business.username': username,
        showSaveUsingExistingUser: false
      });
    },

    _setUniqueSurvey() {
      if (this.onsiteSurveysCount === 1) {
        // eslint-disable-next-line ember-suave/prefer-destructuring
        const defaultSurvey = this.onsiteSurveys[0];
        Ember.set(this, 'selectedSurvey', defaultSurvey);
        Ember.set(this, 'business.surveyId', defaultSurvey.survey_id);
      }
    },

    _subscriptionDoesntNeedsSurvey(subscriptionName) {
      const doesntNeedSurvey = subscriptionName !== _business.default.subscriptionNames.LIVE_SURVEY && subscriptionName !== _business.default.subscriptionNames.LIVE_EXPERIENCE;
      Ember.set(this, 'subscriptionNeedsSurvey', !doesntNeedSurvey);
      return doesntNeedSurvey;
    },

    actions: {
      changeSubscriptionCallback(subscription) {
        const subscriptionName = subscription.name;

        if (this._subscriptionDoesntNeedsSurvey(subscriptionName)) {
          Ember.set(this, 'selectedSurvey', null);
        } else {
          this._setUniqueSurvey();
        }
      },

      handleChangeSurvey(survey) {
        Ember.set(this, 'selectedSurvey', survey);
        Ember.set(this, 'business.surveyId', survey.survey_id);
      },

      updateUsername(username) {
        this._updateUsername(username);
      },

      updateEmailAddress(email) {
        Ember.set(this, 'business.email', email);

        this._updateUsername(email);
      },

      selectionChanged(selection) {
        this._super(...arguments);

        Ember.set(this, 'selectedSurvey', null);
        const business = this.business;
        let name, subdomain, clusterId;

        if (Ember.isPresent(selection)) {
          clusterId = selection.clusterId;
          name = selection.name;

          if (name) {
            subdomain = name.toLowerCase().replace(/\W/g, '');
          }
        }

        Ember.setProperties(business, {
          name,
          subdomain,
          clusterId
        });
        Ember.set(this, 'salesforce', null);

        if (selection) {
          Ember.set(this, 'isLoadingSalesforce', true);
          this.api.additionalBusinessInfo(selection.clusterId).then(({
            salesforceData,
            onsiteSurveys
          }) => {
            Ember.set(this, 'salesforce', salesforceData);

            if (onsiteSurveys) {
              Ember.set(this, 'onsiteSurveys', onsiteSurveys);

              this._setUniqueSurvey();
            }

            Ember.set(this, 'isLoadingSalesforce', false);
          });
        }
      },

      adminSelectionChanged(selection) {
        if (selection) {
          const business = this.business;
          const adminName = selection.name;
          const email = selection.email;
          const username = selection.username;
          Ember.setProperties(business, {
            adminName,
            email,
            username
          });
          Ember.set(this, 'showAdminForm', true);
        }
      },

      setAdminExistsinTy(value) {
        Ember.set(this, 'adminExistsinTy', value);
        Ember.set(this, 'showAdminForm', !value);

        if (value === false) {
          const business = this.business;
          const [adminName, email, username] = [null, null, null];
          Ember.setProperties(business, {
            adminName,
            email,
            username
          });
        }
      },

      save(usernameExists) {
        if (!usernameExists) {
          /* To handle the case where the user clicks the 'Save'
           * button after having clicked 'Save Using Existing User' before
           * and encounters an error. In that case, we don't want the
           * 'Save Using Existing User' button hanging around.
           */
          Ember.set(this, 'showSaveUsingExistingUser', false);
        }

        Ember.setProperties(this, {
          errorMessage: false,
          isSaving: true
        });
        const business = this.business;
        Ember.setProperties(business, {
          usernameExists,
          subscription: Ember.get(this, 'selectedSubscription.name')
        });
        const selectedSubscription = Ember.get(this, 'business.subscription');
        const shouldSetSurveyToNull = this._subscriptionDoesntNeedsSurvey(selectedSubscription) || this.onsiteSurveysCount === 0;

        if (shouldSetSurveyToNull) {
          Ember.set(this, 'business.surveyId', null);
        }

        business.validate().then(({
          model,
          validations
        }) => {
          if (validations.get('isValid')) {
            model.save().then(b => {
              Ember.set(this, 'isSaving', false);
              this.flashSuccess(() => {
                this.sendAction('transitionToBusiness', b.id);
              });
            }).catch(e => {
              if (Ember.get(e, 'errors[0].detail')) {
                Ember.set(this, 'errorMessage', (0, _arrayToSentence.default)(e.errors.map(error => error.detail)));

                if (e.errors[0].detail === 'username has already been taken') {
                  Ember.set(this, 'showSaveUsingExistingUser', true);
                }
              } else {
                Ember.set(this, 'errorMessage', e.errors);
              }
            }).finally(() => Ember.set(this, 'isSaving', false));
          } else {
            Ember.setProperties(this, {
              errorMessage: validations.get('message'),
              isSaving: false
            });
          }
        });
      }

    }
  });

  _exports.default = _default;
});