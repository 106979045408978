define("blackcat/components/page-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: Object.freeze(['md-breadcrumb-page']),
    pageHeader: Ember.inject.service('page-header'),
    title: Ember.computed.readOnly('pageHeader.title')
  });

  _exports.default = _default;
});