define("blackcat/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N10h40TX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[8],[0,\"Welcome Home\"],[9],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"\\n  Welcome to the Messaging Administration application. Enjoy your stay!\\n\"],[9],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"\\n  Wondering what you can do here?\\n\"],[9],[0,\"\\n\"],[7,\"ul\",true],[8],[0,\"\\n  \"],[7,\"li\",true],[8],[0,\"Create demos to show how amazing Messaging is for a potential customer!\"],[9],[0,\"\\n  \"],[7,\"li\",true],[8],[0,\"Manage industries - not that this really matters anymore since we are only focusing on hotels again.\"],[9],[0,\"\\n  \"],[7,\"li\",true],[8],[0,\"Provide super support for customers having problems by impersonating the customer to look into their account.\"],[9],[0,\"\\n  \"],[7,\"li\",true],[8],[0,\"Activate/deactivate a customer's subscription.\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "blackcat/index/template.hbs"
    }
  });

  _exports.default = _default;
});